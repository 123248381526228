import {gql} from '@apollo/client'
import timeFieldPreferencesFragment from '@data/fragments/preferences/timeFieldPreferencesFragment.main'

export const setTimePreferencesMutation = gql`
  mutation setPreferenceTime($websiteId: ID!, $time: String!) {
    setUserPreferences(websiteId: $websiteId, time: $time) {
      _id
      time
      deliverAt
      ...timeFieldPreferences
    }
  }
  ${timeFieldPreferencesFragment}
`

export const getDeliveryTimeDataQuery = gql`
  query getDeliveryTimeData($websiteId: ID!) {
    preferences: userPreferences(websiteId: $websiteId) {
      _id
      time
      store {
        schedulingOrdersDays
        minimumAnticipationDays
        availableScheduleDaysOptions {
          label
          value
        }
        schedule {
          itsOpenNow: itsAvailableAt(websiteId: $websiteId)
        }
      }
    }
  }
`
