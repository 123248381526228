import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

import useWebsiteId from './useWebsiteId'

const COUNTRIES = ['CO', 'MX', 'PE']

export default function useShouldRequireCity() {
  const websiteId = useWebsiteId()
  const {website} = useApolloQuery({
    query: gql`
      query getWebsiteShouldRequireCity($websiteId: ID) {
        website(websiteId: $websiteId) {
          _id
          shouldRequireCity
          countryCode
        }
      }
    `,
    variables: {websiteId},
    omit: !websiteId
  })

  return website?.shouldRequireCity && COUNTRIES.includes(website.countryCode)
}
