import React from 'react'
import timeFieldPreferencesFragment from '@data/fragments/preferences/timeFieldPreferencesFragment.main'
import withMessage from '@packages/justo-parts/lib/decorators/withMessage'
import withMutation from '@packages/react-apollo-decorators/lib/withMutation'
import TimeField from '@page-components/Checkout/Checkout/Delivery/Duration/Update/TimeField'
import gql from 'graphql-tag'

import {setTimePreferencesVariables} from './__generated__/setTimePreferences'

export interface TimeProps {
  showMessage?: any
  setTimePreferencesUpdateBasicOptions?: any
  preferences: setTimePreferencesVariables
  selectorProps?: {
    menuPosition?: string
    styles?: any
  }
  onChange?: () => void
}

@withMutation(gql`
  mutation setTimePreferencesUpdateBasicOptions($websiteId: ID, $time: String) {
    setUserPreferences(websiteId: $websiteId, time: $time) {
      _id
      time
      deliverAt
      ...timeFieldPreferences
    }
  }
  ${timeFieldPreferencesFragment}
`)
@withMessage
export default class Time extends React.Component<TimeProps> {
  render() {
    const save = async time => {
      if (this.props.onChange) this.props.onChange()
      return await this.props.setTimePreferencesUpdateBasicOptions({
        websiteId: this.props.preferences.websiteId,
        time
      })
    }

    return (
      <TimeField
        preferences={this.props.preferences}
        websiteId={this.props.preferences.websiteId}
        selectorProps={this.props.selectorProps}
        save={save}
      />
    )
  }
}
