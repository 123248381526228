import React from 'react'
import AddressOutOfBounds from '@page-components/Checkout/Checkout/Delivery/Address/AddressOutOfBounds'

import styles from './styles.module.css'

export default function StoreMessage(props) {
  const {storeId, placeId} = props.preferences
  if (!placeId) return null
  if (storeId) return null

  return (
    <div className={styles.container}>
      <AddressOutOfBounds placeId={placeId} />
    </div>
  )
}
