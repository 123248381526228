import React from 'react'
import AutoForm from '@components/AutoForm'
import Text from '@packages/justo-parts/lib/components/fields/Text'
import {useTranslation} from 'next-i18next'
import {Field} from 'simple-react-form'

import fragment from './fragment'

import styles from './styles.module.css'

export default function TableName({showLabel = true, ...props}) {
  const {preferences} = props
  const {t} = useTranslation('website', {keyPrefix: 'order.options.updateBasicOptions.tableName'})
  return (
    <div className={styles.container}>
      <AutoForm
        autoSave
        useFormTag={false}
        mutation="setUserPreferences"
        fragment={fragment}
        doc={{websiteId: preferences.websiteId, tableName: preferences.tableName}}>
        <Field
          fieldName="tableName"
          label={showLabel ? t('labelWhichTable') : null}
          placeholder={t('placeholderSelectTable')}
          type={Text}
        />
      </AutoForm>
    </div>
  )
}
