import {WEBSITE_NO_STORE_MESSAGE_CACHED_QUERY} from '@data/queries/website/websiteNoStoreMessage'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery as useQuery} from 'apollo-hooks'

interface UseAddressOutOfBoundsParams {
  storeId?: string
  placeId?: string
}

export default function useAddressOutOfBounds({storeId, placeId}: UseAddressOutOfBoundsParams) {
  const websiteId = useWebsiteId()

  const {website} = useQuery({
    query: WEBSITE_NO_STORE_MESSAGE_CACHED_QUERY,
    variables: {websiteId},
    fetchPolicy: 'cache-first',
    partial: true
  })

  if (!placeId || storeId) {
    return null
  }

  let outOfDeliveryZoneMessage = null
  if (website) {
    outOfDeliveryZoneMessage =
      website.menuConfiguration.deliveryNoStoreMessage || 'Lo sentimos, no llegamos a tu dirección'
  }

  return outOfDeliveryZoneMessage
}
