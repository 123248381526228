import {useState} from 'react'
import {useMutation} from '@apollo/client'
import isServerSide from '@helpers/misc/isServerSide'
import {getSchedulingDays, isValidTime} from '@helpers/websites/scheduleHelpers'
import useMessage from '@hooks/useMessage'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery as useQuery} from 'apollo-hooks'
import debounce from 'lodash/debounce'

import {
  getDeliveryTimeData,
  getDeliveryTimeData_preferences,
  getDeliveryTimeDataVariables
} from './__generated__/getDeliveryTimeData'
import {setPreferenceTime, setPreferenceTimeVariables} from './__generated__/setPreferenceTime'
import useDeliveryTimeDay from './hooks/useDeliveryTimeDay'
import useDeliveryTimeTime from './hooks/useDeliveryTimeTime'
import useDeliveryTimeType from './hooks/useDeliveryTimeType'
import {getDeliveryTimeDataQuery, setTimePreferencesMutation} from './queries'

export interface DefaultPreferences {
  store: null
  time: null
}

const defaultPreferences: DefaultPreferences = {
  store: null,
  time: null
}

export type Preferences = DefaultPreferences | getDeliveryTimeData_preferences | null

export const deliveryTypeIsField = (
  deliveryType: any[] | {label: string; value: number}
): deliveryType is {label: string; value: number} => {
  return Array.isArray(deliveryType) === false
}

export default function useTimeField() {
  const websiteId = useWebsiteId()
  const showMessageHook = useMessage()
  const showMessage = debounce(showMessageHook as any, 300)

  const {preferences = defaultPreferences, loading: loadingPreferences} = useQuery<
    getDeliveryTimeData,
    getDeliveryTimeDataVariables
  >({
    query: getDeliveryTimeDataQuery,
    variables: {websiteId},
    omit: !websiteId || isServerSide(),
    partial: true
  })
  const [updatedTime, setUpdatedTime] = useState(preferences?.time)
  const [setPreferenceTime] = useMutation<setPreferenceTime, setPreferenceTimeVariables>(
    setTimePreferencesMutation
  )
  const updateTimePreference = async (time: string) => {
    if (updatedTime === time) return

    try {
      if (isValidTime(time, getSchedulingDays(preferences))) {
        setUpdatedTime(time)
        await setPreferenceTime({variables: {time, websiteId}})
        showMessage('Se ha actualizado la programación del pedido')
      } else {
        showMessage('Programación inválida')
      }
    } catch (error) {
      if (error instanceof Error) showMessage(error.message)
    }
  }

  const {deliveryTimeType, deliveryTimeTypeOptions, setDeliveryTimeType} = useDeliveryTimeType({
    preferences,
    loadingPreferences,
    setPreferenceTime: updateTimePreference
  })
  const {deliveryTimeDay, deliveryTimeDayOptions, setDeliveryTimeDay} = useDeliveryTimeDay({
    preferences,
    loadingPreferences,
    setPreferenceTime: updateTimePreference
  })
  const {deliveryTimeTime, deliveryTimeTimeOptions, setDeliveryTimeTime, timeOptionsNetworkStatus} =
    useDeliveryTimeTime({
      preferences,
      loadingPreferences,
      setPreferenceTime: updateTimePreference
    })

  return {
    time: preferences?.time,
    updateTimePreference,

    deliveryTimeType,
    deliveryTimeTypeOptions,
    setDeliveryTimeType,

    deliveryTimeDay,
    deliveryTimeDayOptions,
    setDeliveryTimeDay,

    deliveryTimeTime,
    deliveryTimeTimeOptions,
    setDeliveryTimeTime,
    timeOptionsNetworkStatus
  }
}
