import React from 'react'
import {EVENTS, sendPosthogData} from '@helpers/posthog'
import useCheckoutVersion from '@hooks/useCheckoutVersion'
import useState from '@hooks/useState'
import {useApolloQuery as useQuery} from 'apollo-hooks'
import gql from 'graphql-tag'
import {useTranslation} from 'next-i18next'

import SetPreferredStore from './SetPreferredStore'

import styles from './styles.module.css'

export default function SelectPreferredStore(props) {
  const {preferences} = props
  const [show, setShow] = useState(false)
  const checkoutVersion = useCheckoutVersion()
  const {t} = useTranslation('website', {keyPrefix: 'order.options.updateBasicOptions'})
  const {stores} = useQuery({
    query: gql`
      query getStoresInCoverage($websiteId: ID, $placeId: ID) {
        stores: storesInCoverage(websiteId: $websiteId, placeId: $placeId) {
          _id
          name
          address {
            address
          }
        }
      }
    `,
    partial: true,
    omit: !preferences.websiteId || !preferences.placeId,
    variables: {
      websiteId: preferences.websiteId,
      placeId: preferences.placeId
    }
  })

  if (!preferences.store || !stores) {
    return <div className={styles.container}>{t('address.example')}</div>
  }

  if (stores.length <= 1) {
    return <div className={styles.container}>{t('address.example')}</div>
  }

  return (
    <>
      {show ? (
        <SetPreferredStore stores={stores} close={() => setShow(false)} preferences={preferences} />
      ) : null}
      <a
        className={styles.container}
        onClick={() => {
          setShow(true)
          sendPosthogData(EVENTS.clicked.changePreferredStore, {checkoutVersion})
        }}>
        {preferences.preferredDeliveryStoreId
          ? t('storeSelector.preferredStore', {storeName: preferences.store.name})
          : t('storeSelector.selectedStore', {storeName: preferences.store.name})}
      </a>
    </>
  )
}
