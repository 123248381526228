import React from 'react'
import Button from '@components/Button'
import Modal from '@components/Modal'
import deliveryFragment from '@data/fragments/checkout/deliveryFragment'
import classnames from '@helpers/misc/classnames'
import useMessage from '@hooks/useMessage'
import useState from '@hooks/useState'
import Select from '@packages/justo-parts/lib/components/fields/Select'
import {useMutate} from 'apollo-hooks'
import gql from 'graphql-tag'

import styles from './styles.module.css'

export default function SetPreferredStore(props) {
  const {preferences, close, stores} = props
  const mutate = useMutate()
  const showMessage = useMessage()
  const [value, setValue] = useState(preferences.preferredDeliveryStoreId)

  const options = stores
    ? stores.map((store, index) => {
        return {
          store: store,
          label: store.name,
          value: store._id
        }
      })
    : []

  const save = async preferredDeliveryStoreId => {
    try {
      await mutate({
        mutation: gql`
          mutation setUserPreferredDeliveryStoreIdPreferences(
            $websiteId: ID
            $preferredDeliveryStoreId: ID
          ) {
            setUserPreferences(
              websiteId: $websiteId
              preferredDeliveryStoreId: $preferredDeliveryStoreId
            ) {
              ...checkoutDeliveryPreferences
            }
          }
          ${deliveryFragment}
        `,
        variables: {websiteId: preferences.websiteId, preferredDeliveryStoreId},
        refetchQueries: ['getCheckout', 'getMyOrderPreferences']
      })
      close()
    } catch (error) {
      showMessage(error)
    }
  }

  return (
    <Modal
      close={close}
      customClass={classnames(styles.modal, 'submodal')}
      topStyle={styles.modal}
      bottomStyle={styles.modal}
      top={<h1>Selecciona un local</h1>}
      bottom={
        <div>
          <Button onClick={() => close()}>Volver</Button>
          <Button disabled={options.length <= 1} primary onClick={() => save(value)}>
            {value ? 'Seleccionar local' : 'Seleccion automática'}
          </Button>
        </div>
      }>
      {options.length === 1 ? (
        <div>El único local que entrega a tu dirección es {preferences.store.fullName}</div>
      ) : (
        <div>
          <div className="label">Selecciona un local que llegue a tu dirección</div>
          <div className="description">Estan en orden de local más cercano a más lejano</div>
          <Select
            value={value}
            onChange={setValue}
            options={options}
            placeholder="Selecciona un local"
          />
          <div className="description">
            Automáticamente te asignamos el local más cercano para tí. Selecciona uno de la lista
            solo si quieres que no se asigne automáticamente
          </div>
          <div className={styles.height}></div>
        </div>
      )}
    </Modal>
  )
}
