import {useCallback, useEffect, useMemo} from 'react'
import {NetworkStatus} from '@apollo/client'
import dayjs from '@helpers/dayjs'
import isServerSide from '@helpers/misc/isServerSide'
import {getTimeValue} from '@helpers/websites/scheduleHelpers'
import {Preferences} from '@hooks/useTimeField'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery as useQuery} from 'apollo-hooks'

import {DELIVER_NOW_TIME} from '../../constants'
import {Option} from '../../types'

import {
  getDeliveryTimeTimeOptions,
  getDeliveryTimeTimeOptionsVariables
} from './__generated__/getDeliveryTimeTimeOptions'
import {getDeliveryTimeTimeOptionsFn, SelectOption} from './helpers'
import {getDeliveryTimeTimeOptionsQuery} from './queries'

export interface UseDeliveryTimeTimeParams {
  preferences: Preferences
  loadingPreferences: boolean
  setPreferenceTime: (time: string) => void
}

interface UseDeliveryTimeReturn {
  deliveryTimeTime?: Option<string>
  deliveryTimeTimeOptions: SelectOption[]
  setDeliveryTimeTime: (time: Option<string>) => void
  timeOptionsNetworkStatus: NetworkStatus
}

export default function useDeliveryTimeTime({
  preferences,
  loadingPreferences,
  setPreferenceTime
}: UseDeliveryTimeTimeParams): UseDeliveryTimeReturn {
  const websiteId = useWebsiteId()
  const preferencesTime = preferences?.time
  const {days, time} = getTimeValue(preferencesTime)

  const setDeliveryTimeTime = useCallback(
    (timeOption: Option<string>) => {
      const newTime = `${timeOption.value}+${days}`
      setPreferenceTime(newTime)
    },
    [days, setPreferenceTime]
  )

  // get options
  const date = dayjs()
    .add(days, 'days')
    .startOf('day')
    .add(3, 'hours') // to fix tz issues
    .format()
  const {
    preferences: optionPreferences,
    loading: loadingOptions,
    networkStatus
  } = useQuery<getDeliveryTimeTimeOptions, getDeliveryTimeTimeOptionsVariables>({
    query: getDeliveryTimeTimeOptionsQuery,
    variables: {websiteId, date},
    omit: !websiteId || !date || isServerSide(),
    fetchPolicy: 'network-only',
    partial: true
  })
  const options = useMemo(
    () => getDeliveryTimeTimeOptionsFn(optionPreferences?.timeOptions) ?? [],
    [optionPreferences?.timeOptions]
  )
  const selectedOption = options.find(option => option.value === time)

  useEffect(() => {
    const hasFinishedLoading = !loadingPreferences && !loadingOptions && time && options?.length
    const isScheduling = preferencesTime && preferencesTime !== DELIVER_NOW_TIME
    const selectedTimeIsUnavailable = !selectedOption
    const enabledOption = options.find(option => !option.isDisabled)
    if (hasFinishedLoading && isScheduling && selectedTimeIsUnavailable && enabledOption) {
      setDeliveryTimeTime({label: '', value: enabledOption.value})
    }
  }, [
    time,
    options,
    selectedOption,
    loadingPreferences,
    loadingOptions,
    setDeliveryTimeTime,
    preferencesTime
  ])

  return {
    deliveryTimeTime: selectedOption,
    deliveryTimeTimeOptions: options,
    setDeliveryTimeTime,
    timeOptionsNetworkStatus: networkStatus
  }
}
