import React from 'react'
import {FaExclamationCircle} from 'react-icons/fa'

import styles from './styles.module.css'

export default function OutOfBoundsAlert({message}) {
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <FaExclamationCircle size={15} className={styles.icon} />
        {message}
      </div>
    </div>
  )
}
