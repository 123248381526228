import {DELIVER_NOW_TIME} from '../../constants'
import {DeliveryTimeTypes, Option} from '../../types'

import {getDeliveryTimeType, getDeliveryTimeTypeOptions} from './helpers'

export interface UseDeliveryTimeTypeParams {
  preferences: any
  loadingPreferences: boolean
  setPreferenceTime: (time: string) => void
}

interface UseDeliveryTimeTypeReturn {
  deliveryTimeType: Option<number> | null
  deliveryTimeTypeOptions: Option<number>[]
  setDeliveryTimeType: (deliveryTimeType: DeliveryTimeTypes) => void
}

export default function useDeliveryTimeType({
  preferences,
  setPreferenceTime
}: UseDeliveryTimeTypeParams): UseDeliveryTimeTypeReturn {
  const deliveryTimeType = getDeliveryTimeType(preferences?.time)
  const deliveryTimeTypeOptions = getDeliveryTimeTypeOptions(preferences?.store) ?? []

  const setDeliveryTimeType = (deliveryTimeTypeParam: DeliveryTimeTypes) => {
    const isTypeChanging = deliveryTimeType?.value !== deliveryTimeTypeParam
    const isNewTypeDeliverNow = deliveryTimeTypeParam === DeliveryTimeTypes.DELIVER_NOW
    if (isTypeChanging && isNewTypeDeliverNow) {
      return setPreferenceTime(DELIVER_NOW_TIME)
    }

    const options = preferences?.store?.availableScheduleDaysOptions ?? []
    const hasAvailableOptions = options?.length
    if (isTypeChanging && !isNewTypeDeliverNow && hasAvailableOptions) {
      const time = `00:01+${options[0].value}`
      return setPreferenceTime(time)
    }
  }

  return {
    deliveryTimeType,
    deliveryTimeTypeOptions,
    setDeliveryTimeType
  }
}
