import React from 'react'
import {MdStore} from 'react-icons/md'

import styles from './styles.module.css'

export default function StoreName({
  preferences,
  website,
  showTitle = false,
  isOnMenu = false,
  isCheckoutV2 = false
}: {
  preferences: any
  website: {showStoreName?: boolean}
  showTitle?: boolean
  isOnMenu?: boolean
  isCheckoutV2?: boolean
}) {
  const {showStoreName} = website || {}
  const {store} = preferences

  if (!showStoreName) return null
  if (!store || !store.fullName) return null

  let classNameComponent = styles.containerIcon

  if (isOnMenu) {
    classNameComponent = styles.menu
  }

  if (isCheckoutV2) {
    classNameComponent = styles.checkoutV2
  }

  return (
    <>
      {showTitle ? <div className={styles.title}>Local</div> : null}
      <div className={classNameComponent}>
        <MdStore size={20} className="mr-1" /> {store.fullName}
      </div>
    </>
  )
}
