import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import useShouldRequireCity from '@hooks/useShouldRequireCity'
import useInitialData from '@page-components/Layout/useInitialData'
import {useTranslation} from 'next-i18next'

import cities from '../cities'

export default function CitySelector(props: CitySelectorProps) {
  const {website} = useInitialData()
  const shouldRequireCity = useShouldRequireCity()
  const countryCities = cities[website.countryCode]
  const [selectedCity, setSelectedCity] = useState<CityOption>(props.city ?? null)
  const {t} = useTranslation('website', {
    keyPrefix: 'order.options.updateBasicOptions.citySelector'
  })

  useEffect(() => {
    props.changeSelectedCity(selectedCity)
  }, [selectedCity])

  if (!shouldRequireCity || countryCities == undefined) return null

  const cityOptions: CityOption[] = countryCities.map(city => ({
    value: city,
    label: city
  }))

  function handleSelect(newCity: CityOption) {
    setSelectedCity(newCity)
  }

  return (
    <div className="city-selector">
      <Select
        options={cityOptions}
        onChange={handleSelect}
        value={selectedCity}
        placeholder={t('placeholder')}
        isClearable
        isSearchable
        components={{
          IndicatorSeparator: null
        }}
        styles={{
          menu: provided => ({
            ...provided,
            zIndex: 1001
          })
        }}
        classNamePrefix="orion-select"
      />
    </div>
  )
}

interface CitySelectorProps {
  city?: CityOption
  changeSelectedCity: (city: CityOption) => void
}

export interface CityOption {
  value: string
  label: string
}
