import {Option} from '@hooks/useTimeField/types'

import {BackendOption, backendOptionToOption, isNotNull, Nullable} from '../helpers'

interface TimeOption extends BackendOption<string> {
  disabled: Nullable<boolean>
}

export interface SelectOption extends Option<string> {
  disabled: boolean
  isDisabled: boolean
}

export const getDeliveryTimeTimeOptionsFn = (
  preferencesTimeOptions?: Nullable<TimeOption>[] | null
): SelectOption[] => {
  let timeOptions: SelectOption[] = []

  if (preferencesTimeOptions) {
    const filteredOptions = preferencesTimeOptions.filter(isNotNull)
    const queryOptions: SelectOption[] = filteredOptions.map(option => {
      const newOption = backendOptionToOption(option)
      return {
        ...newOption,
        disabled: Boolean(option.disabled),
        isDisabled: Boolean(option.disabled)
      }
    })
    const isAnyOptionEnabled = filteredOptions.some(option => !option.disabled)
    timeOptions = isAnyOptionEnabled ? queryOptions : []
  }

  return timeOptions
}
