import {gql} from '@apollo/client'

export const getDeliveryTimeTimeOptionsQuery = gql`
  query getDeliveryTimeTimeOptions($websiteId: ID!, $date: Date!) {
    preferences: userPreferences(websiteId: $websiteId) {
      _id
      timeOptions(date: $date) {
        label
        value
        disabled
      }
    }
  }
`
