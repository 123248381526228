import React, {useCallback, useEffect} from 'react'
import RSelect from 'react-select'
import {NetworkStatus} from '@apollo/client'
import {getTimeValue} from '@helpers/websites/scheduleHelpers'
import useTimeField, {deliveryTypeIsField} from '@hooks/useTimeField'
import {useTranslation} from 'next-i18next'

import {DELIVERY_VALUES_ENUM} from './useTimeField'

import styles from './styles.module.css'

export const DELIVERY_TIME_TYPE_SELECTOR_ID = 'delivery-time-type-selector'

export default function TimeField(props) {
  const preferences = props.preferences || {}
  const {store} = preferences
  const statesTimeNull = ['undefined', 'null', 'now']
  const {t} = useTranslation('checkoutv3', {keyPrefix: 'schedule.edit'})

  const {
    time,
    updateTimePreference,
    deliveryTimeType,
    deliveryTimeTypeOptions,
    setDeliveryTimeType,
    deliveryTimeDay,
    deliveryTimeDayOptions,
    setDeliveryTimeDay,
    deliveryTimeTime,
    deliveryTimeTimeOptions,
    setDeliveryTimeTime,
    timeOptionsNetworkStatus
  } = useTimeField()

  const timeInvalid = () => {
    return !time || statesTimeNull.some(timeNull => time.includes(timeNull))
  }

  const onDeliveryTypeChange = newDeliveryType => {
    setDeliveryTimeType(newDeliveryType?.value)
  }

  useEffect(() => {
    if (
      deliveryTimeTypeOptions.length === 1 &&
      (Array.isArray(deliveryTimeType) ||
        (deliveryTimeType && deliveryTimeType?.value !== deliveryTimeTypeOptions[0].value))
    ) {
      onDeliveryTypeChange(deliveryTimeTypeOptions[0])
      if (
        timeInvalid() &&
        (deliveryTimeTypeOptions[0].value as any) === DELIVERY_VALUES_ENUM.schedule_order &&
        store?.availableScheduleDaysOptions?.length
      ) {
        onFormChange?.({days: store?.availableScheduleDaysOptions[0].value})
      }
    }
  }, [deliveryTimeType, deliveryTimeTypeOptions, onDeliveryTypeChange, store, timeInvalid])

  const updateSelectedOption = useCallback(() => {
    const {days: currentDay} = getTimeValue(time)
    const enabledTimeOption = Array.isArray(deliveryTimeTimeOptions)
      ? deliveryTimeTimeOptions.find(option => !option.isDisabled)
      : null
    if (
      enabledTimeOption &&
      deliveryTypeIsField(deliveryTimeType) &&
      (deliveryTimeType?.value as any) === DELIVERY_VALUES_ENUM.schedule_order
    ) {
      onFormChange({days: currentDay, time: enabledTimeOption.value})
      return enabledTimeOption
    }
    return null
  }, [time, deliveryTimeTimeOptions, deliveryTimeType])

  useEffect(() => {
    if (!timeInvalid() && deliveryTimeTypeOptions.length > 1) {
      onDeliveryTypeChange(deliveryTimeTypeOptions[1])
    }
  }, [])

  if (!store) return null

  const onFormChange = ({days: newDay, time: newHourRange}: {days: string; time?: string}) => {
    const {days: currentDay, time: currentHourRange} = getTimeValue(time)

    if (newDay === currentDay && newHourRange === currentHourRange) return

    // if the day changes or is null, set hour range to null.
    if (!newDay) {
      newHourRange = null
    }
    if (newDay !== currentDay) {
      const option = updateSelectedOption()
      newHourRange = option ? option.value : null
    }

    const newTime = `${newHourRange}+${newDay}`
    // setTime(newTime)

    // only update backend if day and hour range are non-null.
    newHourRange && newDay && updateTimePreference(newTime)
  }

  return (
    <div>
      {deliveryTimeTypeOptions.length ? (
        <RSelect
          {...props.selectorProps}
          id={DELIVERY_TIME_TYPE_SELECTOR_ID}
          className={styles.select}
          placeholder="Tipo de entrega"
          name="deliveryType"
          classNamePrefix="orion-select"
          value={deliveryTimeType}
          defaultValue={deliveryTimeType}
          options={deliveryTimeTypeOptions}
          onChange={onDeliveryTypeChange}
          isSearchable={false}
        />
      ) : (
        <p className={styles.noScheduling}>No estamos recibiendo pedidos</p>
      )}

      {deliveryTypeIsField(deliveryTimeType) &&
        (deliveryTimeType?.value as any) === DELIVERY_VALUES_ENUM.schedule_order &&
        (!deliveryTimeDayOptions.length ? (
          <p className={styles.noScheduling}>No estamos recibiendo pedidos</p>
        ) : (
          <>
            <RSelect
              {...props.selectorProps}
              className={styles.select}
              placeholder="Selecciona el día"
              name="days"
              classNamePrefix="orion-select"
              value={deliveryTimeDay}
              defaultValue={deliveryTimeDay}
              options={deliveryTimeDayOptions}
              onChange={setDeliveryTimeDay}
              isSearchable={false}
            />
            {deliveryTimeTimeOptions?.length === 0 &&
            timeOptionsNetworkStatus === NetworkStatus.ready ? (
              <p className="text-red-800 font-semibold">Este día no tiene horarios disponibles</p>
            ) : (
              <RSelect
                {...props.selectorProps}
                className={styles.select}
                placeholder={'Selecciona el horario'}
                name="time"
                classNamePrefix="orion-select"
                value={deliveryTimeTime}
                defaultValue={deliveryTimeTime}
                options={deliveryTimeTimeOptions}
                onChange={setDeliveryTimeTime}
                isSearchable={false}
              />
            )}
          </>
        ))}
    </div>
  )
}
