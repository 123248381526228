import gql from 'graphql-tag'

export const WEBSITE_NO_STORE_MESSAGE_CACHED_QUERY = gql`
  query getWebsiteNoStoreMessage_cached($websiteId: ID) {
    website(websiteId: $websiteId) {
      _id
      menuConfiguration {
        deliveryNoStoreMessage
      }
    }
  }
`
