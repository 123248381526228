import {getTimeValue} from '@helpers/websites/scheduleHelpers'
import {Preferences} from '@hooks/useTimeField'

import {Option} from '../../types'
import {backendOptionToOption, isNotUndefinedNorNull} from '../helpers'

export interface UseDeliveryTimeDayParams {
  preferences: Preferences
  loadingPreferences: boolean
  setPreferenceTime: (...args: any[]) => void
}

interface UseDeliveryTimeDayReturn {
  deliveryTimeDay?: Option<string> | null
  deliveryTimeDayOptions: Option<string>[]
  setDeliveryTimeDay: (deliveryTimeDay: Option<string>) => void
}

export default function useDeliveryTimeDay({
  preferences,
  setPreferenceTime
}: UseDeliveryTimeDayParams): UseDeliveryTimeDayReturn {
  const {days, time} = getTimeValue(preferences?.time)
  const setDeliveryTimeDay = (dayOption: Option<string>) => {
    const newTime = `${time}+${dayOption.value}`
    setPreferenceTime(newTime)
  }
  const options =
    preferences?.store?.availableScheduleDaysOptions?.filter(isNotUndefinedNorNull) ?? []
  const selectedOption = options.find(option => option.value === days)

  return {
    deliveryTimeDay: backendOptionToOption(selectedOption),
    deliveryTimeDayOptions: options.map(option => backendOptionToOption(option)),
    setDeliveryTimeDay
  }
}
