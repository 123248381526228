const availableCities = {
  CO: [
    'Bogotá',
    'Medellín',
    'Cali',
    'Chía',
    'Soacha',
    'Jamundí',
    'Envigado',
    'Itagüí',
    'Barranquilla',
    'Puerto Colombia',
    'Cartagena',
    'Bucaramanga',
    'Floridablanca',
    'Girón',
    'Pereira',
    'Dosquebradas',
    'Manizales',
    'Villamaría',
    'Armenia',
    'La estrella',
    'Poblado',
    'Laureles',
    'Sabaneta'
  ],
  PE: [
    'Ancón',
    'Ate',
    'Barranco',
    'Breña',
    'Carabayllo',
    'Cercado de Lima',
    'Chaclacayo',
    'Chorrillos',
    'Cieneguilla',
    'Comas',
    'El agustino',
    'Independencia',
    'Jesús maría',
    'La molina',
    'La victoria',
    'Lince',
    'Los olivos',
    'Lurigancho',
    'Lurín',
    'Magdalena del mar',
    'Miraflores',
    'Pachacámac',
    'Pucusana',
    'Pueblo libre',
    'Puente piedra',
    'Punta hermosa',
    'Punta negra',
    'Rímac',
    'San bartolo',
    'San borja',
    'San isidro',
    'San Juan de Lurigancho',
    'San Juan de Miraflores',
    'San Luis',
    'San Martin de Porres',
    'San Miguel',
    'Santa Anita',
    'Santa María del Mar',
    'Santa Rosa',
    'Santiago de Surco',
    'Surquillo',
    'Villa el Salvador',
    'Villa Maria del Triunfo'
  ],
  MX: [
    '10 DE abril',
    '10 DE mayo',
    '12 DE diciembre',
    '15 DE agosto',
    '16 DE septiembre',
    '19 DE mayo',
    '1A AMPLIACION SANTIAGO ACAHUALTEPEC',
    '1RA VICTORIA',
    '1RA VICTORIA SECCION BOSQUES',
    '1o DE MAYO',
    '2 DE octubre',
    '20 DE NOVIEMBRE (AMPL)',
    '20 DE noviembre',
    '24 DE abril',
    '25 DE julio',
    '26 DE julio',
    '2A AMPLIACION SANTIAGO ACAHUALTEPEC I',
    '2A AMPLIACION SANTIAGO ACAHUALTEPEC II',
    '2DA  JALALPA TEPITO (AMPL)',
    '2DA EL PIRUL (AMPL)',
    '3 DE mayo',
    '5 DE mayo',
    '51 LEGISLATURA',
    '5TO TRAMO DE 20 DE NOVIEMBRE',
    '6 DE junio',
    '7 DE JULIO (AMPL)',
    '7 DE julio',
    '7 DE noviembre',
    '8A DE SAN MIGUEL (AMPL)',
    '9 1/2 - FRANCISCO VILLA (EJERCITO CONSTITUCIONALISTA) (CONJ HAB)',
    'AARON SAENZ',
    'ABDIAS GARCIA SOTO',
    'ABRAHAM GONZALEZ',
    'ACACIAS',
    'ACTIPAN',
    'ACUEDUCTO',
    'ACUEDUCTO DE GUADALUPE (RDCIAL)',
    'ACUEDUCTO DE GUADALUPE (U HAB)',
    'ACUILOTLA',
    'ACULCO (PBLO)',
    'ADOLFO LOPEZ MATEOS',
    'ADOLFO RUIZ CORTINES I',
    'ADOLFO RUIZ CORTINES II',
    'AERONAUTICA MILITAR',
    'AGRICOLA METROPOLITANA',
    'AGRICOLA ORIENTAL I',
    'AGRICOLA ORIENTAL II',
    'AGRICOLA ORIENTAL III',
    'AGRICOLA ORIENTAL IV',
    'AGRICOLA ORIENTAL V',
    'AGRICOLA ORIENTAL VI',
    'AGRICOLA ORIENTAL VII',
    'AGRICOLA ORIENTAL VIII',
    'AGRICULTURA',
    'AGUA BENDITA',
    'AGUILAS 3ER PARQUE',
    'AGUILAS PILARES',
    'AGUILERA',
    'AHUACATITLA',
    'AHUATENCO',
    'AHUEHUETES',
    'AHUEHUETES ANAHUAC',
    'AIDEE SOLS CRDENAS-MATAS ROMERO (U HAB)',
    'AJUSCO HUAYAMILPAS',
    'AJUSCO I',
    'AJUSCO II',
    'AJUSCO III',
    'ALAMOS I',
    'ALAMOS II',
    'ALBARRADAS (U HAB)',
    'ALBERT',
    'ALCANFORES (U HAB)',
    'ALCANTARILLA',
    'ALDANA',
    'ALFALFAR',
    'ALFONSO XIII',
    'ALGARIN',
    'ALIANZA POPULAR REVOLUCIONARIA NORTE (U HAB)',
    'ALIANZA POPULAR REVOLUCIONARIA ORIENTE (U HAB)',
    'ALIANZA POPULAR REVOLUCIONARIA PONIENTE (U HAB)',
    'ALLEPETLALI (U HAB)',
    'ALPES',
    'ALPES (AMPL)',
    'ALTILLO (COND ALTILLO ACASULCO)',
    'ALTILLO (COND ALTILLO UNIVERSIDAD)',
    'ALTOS TEPETLICA',
    'ALVARO OBREGON',
    'ALVARO OBREGON_(FRACC)',
    'AMADO NERVO',
    'AMERICA',
    'AMERICAS UNIDAS-DEL LAGO',
    'AMPL CARACOL',
    'AMPLIACION CANDELARIA',
    'AMPLIACION MIGUEL HIDALGO 2A SECC',
    'AMPLIACION MIGUEL HIDALGO 3A SECC',
    'AMPLIACION MIGUEL HIDALGO 4A SECC',
    'AMPLIACION PETROLERA',
    'AMPLIACION RAMOS MILLAN',
    'AMPLIACION SAN FRANCISCO CULHUACAN (EJ)',
    'AMSA',
    'ANAHUAC DOS LAGOS',
    'ANAHUAC I',
    'ANAHUAC II',
    'ANAHUAC LAGO NORTE',
    'ANAHUAC LAGO SUR',
    'ANAHUAC LOS MANZANOS',
    'ANAHUAC MARIANO ESCOBEDO',
    'ANAHUAC PERALITOS',
    'ANGEL ZIMBRON',
    'ANTORCHA POPULAR I (U HAB)',
    'ANZURES',
    'AO DE JUAREZ',
    'APATLACO',
    'AQUILES SERDAN',
    'ARAGON INGUARAN',
    'ARAGON LA VILLA (ARAGON)',
    'ARBOLEDAS',
    'ARBOLEDAS DE CUAUTEPEC',
    'ARBOLEDAS DE CUAUTEPEC (AMPL)',
    'ARBOLEDAS DEL SUR',
    'ARBOLEDAS POLITOCO',
    'ARCOS DE CENTENARIO',
    'ARENAL',
    'ARENAL GUADALUPE TLALPAN',
    'ARENAL PUERTA TEPEPAN',
    'ARGENTINA ANTIGUA',
    'ARROYO GUADALUPE (U HAB)',
    'ART 4TO CONSTITUCIONAL (U HAB)',
    'ARTES GRAFICAS',
    'ARTURO GAMIZ',
    'ARTURO MARTINEZ',
    'ARVIDE',
    'ASTURIAS',
    'ASTURIAS (AMPL)',
    'ATACAXCO',
    'ATENOR SALAS',
    'ATLAMAXAC',
    'ATLAMAYA',
    'ATLAMPA',
    'ATLANTIDA',
    'ATOCPA SUR',
    'ATOTOLCO',
    'AVANTE',
    'AVE REAL',
    'AVE REAL (AMPL)',
    'AVIACION CIVIL',
    'AVIACION CIVIL (AMPL)',
    'AXOTLA',
    'AYOCATITLA,  ASUNCIN',
    'AZTECA',
    'AZTLAN-APACHES (U HAB)',
    'BAHIA (U HAB)',
    'BALCONES DE CEHUAYO',
    'BANJIDAL',
    'BARRANCA SECA',
    'BARRANCAS DE GUADALUPE',
    'BARRIO 18',
    'BARRIO NORTE',
    'BARROS SIERRA',
    'BATALLON DE SAN PATRICIO (U HAB)',
    'BATAN VIEJO (EL MAESTRO)',
    'BEJERO',
    'BELEM (BARR)',
    'BELEN DE LAS FLORES',
    'BELEN DE LAS FLORES (U HAB)',
    'BELEN DE LAS FLORES SECCION RELLENO',
    'BELEN DE LAS FLORES_',
    'BELISARIO DOMINGUEZ',
    'BELLA VISTA',
    'BELLAVISTA (U HAB)',
    'BELVEDERE',
    'BENITO JUAREZ',
    'BENITO JUAREZ (AMPL)',
    'BONANZA',
    'BONDOJITO',
    'BOSQUE DE CHAPULTEPEC I, II Y III SECCIONES',
    'BOSQUE DE TEPEXIMILPA',
    'BOSQUE RESIDENCIAL DEL SUR (FRACC)',
    'BOSQUES DE CHAPULTEPEC (POLANCO)',
    'BOSQUES DE LAS LOMAS',
    'BOSQUES DE TARANGO',
    'BOSQUES DE TETLAMEYA',
    'BOSQUES DEL PEDREGAL',
    'BUENAVISTA I',
    'BUENAVISTA II',
    'BUENOS AIRES',
    'C T M ARAGON (U)',
    'C T M ARAGON AMPLIACION (U)',
    'CAADA DEL OLIVAR (U HAB)',
    'CABEZA DE JUAREZ I (U HAB)',
    'CABEZA DE JUAREZ II (U HAB)',
    'CABEZA DE JUAREZ III (U HAB)',
    'CACALOTE',
    'CACAMA',
    'CAFETALES I (RDCIAL)',
    'CAFETALES II (RDCIAL)',
    'CALTONGO (BARR)',
    'CALVARIO CAMISETAS',
    'CALZADA JALALPA',
    'CAMINO A SAN JUAN DE ARAGON (PBLO)',
    'CAMPAMENTO 2 DE OCTUBRE I',
    'CAMPAMENTO 2 DE OCTUBRE II',
    'CAMPESTRE ARAGON I',
    'CAMPESTRE ARAGON II',
    'CAMPESTRE CHURUBUSCO',
    'CAMPESTRE COYOACAN (FRACC)',
    'CAMPESTRE POTRERO',
    'CAMPO DE TIRO LOS GAMITOS',
    'CANAL NACIONAL (U HAB)',
    'CANAL NACIONAL 260 (U HAB)',
    'CANAL ONCE (U HAB)',
    'CANANEA (U HAB)',
    'CANDELARIA DELOS PATOS (U HAB)',
    'CANTERA PUENTE DE PIEDRA',
    'CANTIL DEL PEDREGAL',
    'CANUTILLO 3RA SECCION',
    'CANUTILLO PREDIO LA PRESA',
    'CANUTILLO(AGUASCALIENTES)',
    'CAPULTITLAN',
    'CARACOL',
    'CARLOS HANK GONZALEZ',
    'CARLOS ZAPATA VELA',
    'CARMEN SERDAN',
    'CARMEN SERDAN ( U HAB)',
    'CAROLA',
    'CAROLOS PACHECO (U HAB)',
    'CASA BLANCA',
    'CASAS ALEMAN (AMPL) I',
    'CASAS ALEMAN (AMPL) II',
    'CASTILLO CHICO',
    'CASTILLO GRANDE',
    'CASTILLO GRANDE (AMPL)',
    'CAZULCO (BARR)',
    'CE CUALLI OHTLI (U HAB)',
    'CEDRO CHICO',
    'CENTRO DE AZCAPOTZALCO',
    'CENTRO I',
    'CENTRO II',
    'CENTRO III',
    'CENTRO IV',
    'CENTRO URBANO (U HAB)',
    'CENTRO URBANO PRESIDENTE ALEMAN (U HAB)',
    'CENTRO URBANO TLALPAN (U HAB)',
    'CENTRO V',
    'CENTRO VI',
    'CENTRO VII',
    'CENTRO VIII',
    'CERRO DE LA ESTRELLA I',
    'CERRO DE LA ESTRELLA II',
    'CERRO GRANDE',
    'CERRO PRIETO',
    'CHALMA DE GUADALUPE I',
    'CHALMA DE GUADALUPE II',
    'CHAPULTEPEC MORALES (POLANCO)',
    'CHAPULTEPEC POLANCO (POLANCO)',
    'CHICHICASPATL',
    'CHIMALCOYOC',
    'CHIMALISTAC',
    'CHIMILLI',
    'CHINAMPAC DE JUAREZ I',
    'CHINAMPAC DE JUAREZ II',
    'CHINAMPAC DE JUAREZ III',
    'CHINAMPAS DE SANTA MA TOMATLAN',
    'CHURUBUSCO TEPEYAC',
    'CITLALLI',
    'CIUDAD DE LOS DEPORTES',
    'CIUDAD JARDIN',
    'CIUDAD UNIVERSITARIA',
    'CLAVERIA',
    'CLUB DE GOLF MEXICO-SAN BUENAVENTURA',
    'COAPA 2A SECCION-RAMOS MILLAN',
    'COAPA-VILLA CUEMANCO',
    'COCOYOTES',
    'COCOYOTES (AMPL)',
    'COLA DE PATO',
    'COLINAS DE TARANGO',
    'COLINAS DEL BOSQUE-LAS TORTOLAS',
    'COLINAS DEL SUR',
    'COLONIAL IZTAPALAPA (FRACC)',
    'COLTONGO',
    'COMPOSITORES MEXICANOS',
    'CONCIENCIA PROLETARIA',
    'CONCORDIA ZARAGOZA (U HAB)',
    'CONDESA',
    'CONDOMINIO DEL BOSQUE (FRACC)-BOSQUE DE TLALPAN',
    'CONJUNTO HABITACIONAL PEDREGAL DEL LAGO',
    'CONJUNTO URBANO CUEMANCO (U HAB)',
    'CONSEJO AGRARISTA MEXICANO I',
    'CONSEJO AGRARISTA MEXICANO II',
    'CONSTITUCION DE 1917 I',
    'CONSTITUCION DE 1917 II',
    'CONSTITUCION DE LA REPUBLICA',
    'COOPERATIVA CEHUAYO',
    'COOPERATIVA LUIS ENRIQUE RODRGUEZ OROZCO (U HAB)',
    'COPILCO EL ALTO',
    'COPILCO EL BAJO',
    'COPILCO UNIVERSIDAD',
    'CORPUS CHRISTY',
    'CORPUS CURISTY AMPL XOCOMECATLA',
    'CORREDOR SANTA FE',
    'COSMOPOLITA',
    'COSMOPOLITA (AMPL)',
    'COUNTRY CLUB',
    'COVE',
    'CREDITO CONSTRUCTOR',
    'CRISTO REY',
    'CROC CULHUACAN SECC 6 (U HAB)',
    'CRUZ BLANCA',
    'CRUZ DEL FAROL',
    'CRUZ ROJA TEPANTONGO (U HAB)',
    'CTM ATZACOALCO (U HAB)',
    'CTM EL RISCO (U HAB)',
    'CTM IX CULHUACAN 32-33 (U HAB)',
    'CTM IX CULHUACAN ZONA 29-30 (U HAB)',
    'CTM IXA CULHUACAN (U HAB)',
    'CTM V CULHUACAN (U HAB)',
    'CTM VI CULHUACAN (U HAB)',
    'CTM VII CULHUACAN (U HAB)',
    'CTM VIIA CULHUACAN (U HAB)',
    'CTM VIII CULHUACAN (U HAB)',
    'CTM X CULHUACAN (U HAB)',
    'CUADRANTE DE SAN FRANCISCO',
    'CUATRO ARBOLES',
    'CUAUHTEMOC',
    'CUAUHTEMOC PENSIL',
    'CUAUTEPEC DE MADERO',
    'CUAUTEPEC EL ALTO (PBLO)',
    'CUCHILLA AGRICOLA ORIENTAL',
    'CUCHILLA DE PADIERNA',
    'CUCHILLA DEL TESORO',
    'CUCHILLA LA JOYA',
    'CUCHILLA PANTITLAN',
    'CUCHILLA RAMOS MILLAN',
    'CUCHILLAS DEL MORAL (U HAB)',
    'CUILOTEPEC II',
    'CUITLAHUAC',
    'CUITLAHUAC (U HAB)',
    'CUITLHUAC 1 Y 2 (U HAB)',
    'CUITLHUAC 3 Y 4 (U HAB)',
    'CULHUACAN (PBLO)',
    'CULTURA MAYA',
    'DAMIAN CARMONA',
    'DANIEL GARZA',
    'DANIEL GARZA (AMPL)',
    'DE LA CANDELARIA (PBLO)',
    'DE TARANGO (RCNDA)',
    'DEFENSORES DE LA REPUBLICA',
    'DEGOLLADO',
    'DEGOLLADO CHICO',
    'DEL BOSQUE',
    'DEL BOSQUE (POLANCO)',
    'DEL CARMEN',
    'DEL GAS',
    'DEL GAS (AMPL)',
    'DEL MAESTRO',
    'DEL MAR NORTE',
    'DEL MAR SUR',
    'DEL NIO JESUS (BARR)',
    'DEL OBRERO',
    'DEL RECREO',
    'DEL VALLE I',
    'DEL VALLE II',
    'DEL VALLE III',
    'DEL VALLE IV',
    'DEL VALLE V',
    'DEL VALLE VI',
    'DEL VALLE VII',
    'DEMET (U HAB)',
    'DEPORTIVA PENSIL',
    'DESARROLLO URBANO',
    'DESARROLLO URBANO QUETZALCOATL  I',
    'DESARROLLO URBANO QUETZALCOATL  II',
    'DESARROLLO URBANO QUETZALCOATL  III',
    'DIAMANTE',
    'DM NACIONAL',
    'DOCTORES I',
    'DOCTORES II',
    'DOCTORES III',
    'DOCTORES IV',
    'DOCTORES V',
    'DOLORES TLALI',
    'DOS RIOS',
    'DR ALFONSO ORTIZ TIRADO',
    'EBANO (U HAB)',
    'ECOLOGICA NOVEDADES IMPACTO (U HAB)',
    'EDUARDO MOLINA I (U HAB)',
    'EDUARDO MOLINA II (U HAB)',
    'EDUCACION',
    'EJERCITO DE AGUA PRIETA',
    'EJERCITO DE ORIENTE (U HAB) I',
    'EJERCITO DE ORIENTE (U HAB) II',
    'EJIDOS DE SAN PEDRO MARTIR I (NORTE)',
    'EJIDOS DE SAN PEDRO MARTIR II (SUR)',
    'EJIDOS SAN JUAN DE ARAGON 1A SECCION (U HAB)',
    'EJIDOS SAN JUAN DE ARAGON 2A SECCION (U HAB)',
    'EJTO CONSTITUCIONALISTA',
    'EJTO CONSTITUCIONALISTA II ( U HAB)',
    'EJTO CONSTITUCIONALISTA, SUPERMANZANA I ( U HAB)',
    'EJTO CONSTITUCIONALISTA, SUPERMANZANA II ( U HAB)',
    'EJTO CONSTITUCIONALISTA, SUPERMANZANA III (U HAB)',
    'EJTO DE OTE II (U HAB)',
    'EL ARBOL',
    'EL ARBOLILLO',
    'EL ARBOLILLO 1 (U HAB)',
    'EL ARBOLILLO 2 (U HAB)',
    'EL ARBOLILLO 3 (U HAB)',
    'EL ARENAL',
    'EL ARENAL 1A SECCION',
    'EL ARENAL 2A SECCION',
    'EL ARENAL 3A SECCION',
    'EL ARENAL 4A SECCION',
    'EL ARENAL PTO AEREO (FRACC)',
    'EL BATAN',
    'EL BOSQUE',
    'EL BOSQUE 2DA SECCION (AMPL)',
    'EL BOSQUE SECCION TORRES',
    'EL CAPULIN',
    'EL CAPULIN (AMPL)',
    'EL CARACOL',
    'EL CARMEN',
    'EL CENTINELA',
    'EL CONTADERO',
    'EL COYOL (U HAB)',
    'EL CUERNITO',
    'EL DIVISADERO',
    'EL EDEN',
    'EL ERMITAO',
    'EL GAVILLERO',
    'EL JAGUEY-ESTACION PANTACO',
    'EL MANTO',
    'EL MANTO PLAN DE IGUALA',
    'EL MIRADOR',
    'EL MIRADOR - SANTA CATARINA',
    'EL MOLINITO',
    'EL MOLINO',
    'EL MOLINO_',
    'EL OCOTAL',
    'EL OLIVO',
    'EL PARAISO',
    'EL PARQUE',
    'EL PARQUE DE COYOACAN (FRACC)',
    'EL PIRU (FRACC)',
    'EL PIRUL',
    'EL POCITO',
    'EL PRADO',
    'EL RELOJ',
    'EL RETOO',
    'EL RODEO',
    'EL ROSAL',
    'EL ROSARIO',
    'EL ROSARIO (BARR)',
    'EL ROSARIO A (U HAB)',
    'EL ROSARIO B (U HAB)',
    'EL ROSARIO C (U HAB)',
    'EL ROSEDAL I',
    'EL ROSEDAL II',
    'EL RUEDO',
    'EL SANTUARIO',
    'EL SANTUARIO (AMPL)',
    'EL SIFON',
    'EL TANQUE',
    'EL TIANGUILLO',
    'EL TORO',
    'EL TRIANGULO',
    'EL TRIUNFO',
    'EL TRIUNFO (AMPL)',
    'EL VERGEL',
    'EL VERGEL DE COYOACAN ( INFONAVIT EL HUESO) (U HAB)',
    'EL VERGEL TRIANGULO DE LAS AGUJAS I (U HAB)',
    'EL VERGEL TRIANGULO DE LAS AGUJAS II (U HAB)',
    'EL YAQUI',
    'EL ZACATON',
    'EMANCIPACION DEL PUEBLO',
    'EMILIANO ZAPATA',
    'EMILIANO ZAPATA (AMPL)',
    'EMILIANO ZAPATA (U HAB)',
    'EMILIANO ZAPATA 1A',
    'EMILIANO ZAPATA 2A',
    'EMILIO CARRANZA',
    'EMILIO PORTES GIL PEMEX PICACHO (U HAB)',
    'ERMITA',
    'ERMITA ZARAGOZA (U HAB) I',
    'ERMITA ZARAGOZA (U HAB) II',
    'ESCANDON I',
    'ESCANDON II',
    'ESCUADRON 201',
    'ESPARTACO',
    'ESPERANZA',
    'ESTADO DE HIDALGO',
    'ESTADO DE HIDALGO (AMPL)',
    'ESTADO DE HIDALGO_',
    'ESTADO DE VERACRUZ',
    'ESTANZUELA',
    'ESTRELLA',
    'ESTRELLA CULHUACAN',
    'ESTRELLA DEL SUR',
    'EUZKADI',
    'EX EJIDO DE CHURUBUSCO',
    'EX EJIDO SAN FRANCISCO CULHUACAN I',
    'EX EJIDO SAN FRANCISCO CULHUACAN II',
    'EX EJIDO SAN FRANCISCO CULHUACAN III',
    'EX EJIDOS DE LA MAGDALENA MIXIHUCA',
    'EX HACIENDA COAPA',
    'EX HACIENDA SAN JUAN DE DIOS',
    'EX HIPODROMO DE PERALVILLO',
    'EX-ESCUELA DE TIRO',
    'EX-HACIENDA EL ROSARIO',
    'EXTREMADURA INSURGENTES',
    'F P F V (PREDIO EL MOLINO) ( U HAB)',
    'FAJA DE ORO',
    'FEDERAL',
    'FELIPE ANGELES',
    'FELIPE PESCADOR',
    'FERNANDO CASAS ALEMAN',
    'FERRERIA',
    'FERRERIA (U HAB)',
    'FERROCARRILERA INSURGENTES',
    'FIVIPORT (U HAB)',
    'FLOR DE MARIA',
    'FLORESTA-PRADO-VERGEL COAPA',
    'FLORIDA',
    'FOVISSSTE CUCHILLA (U HAB)',
    'FOVISSSTE RIO DE GUADALUPE (U HAB)',
    'FOVISSSTE SAN PEDRO MARTIR ( U HAB)',
    'FOVISSTE ARAGON (U HAB)',
    'FRACCIONAMIENTO COYUYA',
    'FRANCISCO I MADERO',
    'FRANCISCO VILLA',
    'FRANCISCO VILLA (U HAB)',
    'FRESNO',
    'FUEGO NUEVO',
    'FUENTES BROTANTES MIGUEL HIDALGO (U HAB)',
    'FUENTES DE AZCAPOTZALCO-PARQUES DE AZCAPOTZALCO (U HAB)',
    'FUENTES DE ZARAGOZA (U HAB)',
    'FUENTES DEL PEDREGAL',
    'FUENTES Y PEDREGAL DE TEPEPAN',
    'FUERTE DE LORETO - LA ANTENA (U HAB)',
    'GABRIEL HERNANDEZ',
    'GABRIEL HERNANDEZ (AMPL) I',
    'GABRIEL HERNANDEZ (AMPL) II',
    'GABRIEL RAMOS MILLAN',
    'GALEANA',
    'GAMA GAVILAN (U HAB)',
    'GARCIMARRERO',
    'GARCIMARRERO NORTE',
    'GARCIMARRERO REACOMODO',
    'GAVILAN (U HAB)',
    'GENERAL FELIPE BERRIOZABAL',
    'GENERAL PEDRO MARIA ANAYA',
    'GERTRUDIS SANCHEZ 1A SECCION',
    'GERTRUDIS SANCHEZ 2A SECCION',
    'GERTRUDIS SANCHEZ 3A SECCION',
    'GOLONDRINAS',
    'GOLONDRINAS 1RA SECCION',
    'GOLONDRINAS 2DA SECCION',
    'GRACIANO SANCHEZ',
    'GRAL A ROSALES',
    'GRAL C  A  MADRAZO',
    'GRANADA',
    'GRANADA (AMPL)',
    'GRANJAS CABRERA',
    'GRANJAS COAPA',
    'GRANJAS COAPA ORIENTE',
    'GRANJAS ESMERALDA',
    'GRANJAS ESTRELLA I',
    'GRANJAS ESTRELLA II',
    'GRANJAS ESTRELLA III',
    'GRANJAS MEXICO I',
    'GRANJAS MEXICO II',
    'GRANJAS MODERNAS-SAN JUAN DE ARAGON (AMPL)',
    'GRANJAS SAN ANTONIO',
    'GUADALUPE',
    'GUADALUPE (BARR)',
    'GUADALUPE DEL MORAL',
    'GUADALUPE INN',
    'GUADALUPE INSURGENTES',
    'GUADALUPE PROLETARIA',
    'GUADALUPE PROLETARIA (AMPL)',
    'GUADALUPE TEPEYAC',
    'GUADALUPE VICTORIA',
    'GUADALUPE VICTORIA II',
    'GUELATAO DE JUAREZ I (U HAB)',
    'GUELATAO DE JUAREZ II (U HAB)',
    'GUERRERO I',
    'GUERRERO II',
    'GUERRERO III',
    'GUERRERO IV',
    'HACIENDA DE GUADALUPE CHIMALISTAC',
    'HACIENDA SAN JUAN-RINCON DE SAN JUAN-CHIMALI',
    'HACIENDAS DE COYOACAN (FRACC)',
    'HERMOSILLO',
    'HEROE DE NACOZARI',
    'HEROES DE 1910',
    'HEROES DE CHAPULTEPEC',
    'HEROES DE CHURUBUSCO',
    'HEROES DE PADIERNA',
    'HEROES DE PADIERNA I',
    'HEROES DE PADIERNA II',
    'HERON PROAL',
    'HIPODROMO CONDESA',
    'HIPODROMO I',
    'HIPODROMO II',
    'HOGAR Y REDENCION',
    'HOGAR Y SEGURIDAD/NUEVA SANTA MARIA',
    'HOGARES FERROCARRILEROS (U HAB)',
    'HORNOS DE ARAGON (U HAB)',
    'HUASIPUNGO (U HAB)',
    'HUAUTLA DE LAS SALINAS (BARR)',
    'HUAYATLA',
    'HUESO PERIFERICO-ISSSFAM NO. 7 (U HABS)',
    'HUICHAPAN',
    'HUITZICO-LA POBLANITA',
    'IGNACIO ALLENDE',
    'IGNACIO CHAVEZ (U HAB)',
    'IGNACIO MANUEL ALTAMIRANO',
    'IGNACIO ZARAGOZA (U HAB)',
    'IGNACIO ZARAGOZA I',
    'IGNACIO ZARAGOZA II',
    'IMAN',
    'IMAN 580 (U HAB)',
    'INDECO (U HAB)',
    'INDEPENDENCIA',
    'INDEPENDENCIA BATAN NORTE (U HAB)',
    'INDEPENDENCIA BATAN SUR (U HAB)',
    'INDEPENDENCIA SAN RAMON (U HAB)',
    'INDUSTRIAL I',
    'INDUSTRIAL II',
    'INDUSTRIAL PUERTO AEREO (FRACC)',
    'INDUSTRIAL VALLEJO (U HAB)',
    'INFONAVIT (U HAB)',
    'INFONAVIT CAMINO SAN JUAN DE ARAGON (U HAB)',
    'INFONAVIT CULHUACAN ZONA 1 (U HAB)',
    'INFONAVIT CULHUACAN ZONA 2 (U HAB)',
    'INFONAVIT CULHUACAN ZONA 3 (U HAB)',
    'INFONAVIT IZTACALCO (U HAB) I',
    'INFONAVIT IZTACALCO (U HAB) II',
    'INFONAVIT LORETO FABELA (U HAB)',
    'INFONAVIT PROLONGACION DIVISION DEL NORTE (U HAB)',
    'INPI PICOS',
    'INSURGENTES',
    'INSURGENTES CUICUILCO',
    'INSURGENTES MIXCOAC',
    'INSURGENTES SAN BORJA',
    'INTEGRACION LATINOAMERICANA (U HAB)',
    'IRRIGACION',
    'ISIDRO  FABELA (AMPL)',
    'ISIDRO FABELA',
    'ISIDRO FABELA I (PONIENTE)',
    'ISIDRO FABELA II (ORIENTE)',
    'ISSFAM LAS ARMAS (U HAB)',
    'ISSSFAM No. 1 (U HAB)-VILLA TLALPAN',
    'IXTLAHUACAN',
    'IXTLAHUALTONGO',
    'IZTACCIHUATL',
    'JACARANDAS',
    'JAIME S EMILIANO G',
    'JAIME TORRES BODET',
    'JALALPA',
    'JALALPA (AMPL)',
    'JALALPA EL GRANDE',
    'JALALPA TEPITO',
    'JAMAICA',
    'JANITZIO',
    'JARDIN AZPEITIA',
    'JARDIN BALBUENA I',
    'JARDIN BALBUENA II',
    'JARDIN BALBUENA III',
    'JARDINES CEYLAN (U HAB)',
    'JARDINES COAPA-BELISARIO DOMINGUEZ',
    'JARDINES DE CHURUBUSCO',
    'JARDINES DE COYOACAN (FRACC)',
    'JARDINES DE LA PALMA (HUIZACHITO)',
    'JARDINES DE SAN JUAN',
    'JARDINES DE SAN LORENZO',
    'JARDINES DEL AJUSCO',
    'JARDINES DEL LLANO-U.H. VILLA TLATEMPA',
    'JARDINES DEL PEDREGAL',
    'JARDINES DEL SUR',
    'JARDINES EN LA MONTAA',
    'JARDINES TECMA',
    'JESUS DEL MONTE',
    'JORGE NEGRETE',
    'JOSE LOPEZ PORTILLO I',
    'JOSE LOPEZ PORTILLO II',
    'JOSE MA MORELOS Y PAVON (U HAB)',
    'JOSE MARIA MORELOS Y PAVON I (U HAB)',
    'JOSE MARIA MORELOS Y PAVON II (U HAB)',
    'JOSE MARIA PINO SUAREZ',
    'JOSEFA ORTIZ DE DOMINGUEZ',
    'JOYA DE VARGAS',
    'JOYAS DEL PEDREGAL (FRACC)',
    'JOYAS VALLEJO (U HAB)',
    'JUAN DE DIOS BATIZ (U HAB)',
    'JUAN ESCUTIA I',
    'JUAN ESCUTIA II',
    'JUAN ESCUTIA III',
    'JUAN GONZALEZ ROMERO',
    'JUAREZ',
    'JURISTAS',
    'JUSTO SIERRA',
    'JUVENTINO ROSAS',
    'JUVENTINO ROSAS I',
    'JUVENTINO ROSAS II',
    'JUVENTUD UNIDA',
    'KENNEDY (U HAB)',
    'LA  LONJA',
    'LA ANGOSTURA',
    'LA ARAA',
    'LA ASUNCION',
    'LA ASUNCION (BARR)',
    'LA CAADA',
    'LA CANDELARIA TICOMAN (BARR)',
    'LA CANTERA (U HAB)',
    'LA CARBONERA',
    'LA CASCADA',
    'LA CASILDA',
    'LA CEBADA',
    'LA CEBADA (AMPL)',
    'LA COLMENA',
    'LA CONCEPCION',
    'LA CONCEPCION (BARR)',
    'LA CONCEPCION TLACOAPA (BARR)',
    'LA CONCHA',
    'LA CONCHITA',
    'LA CONCHITA I',
    'LA CONCHITA II',
    'LA CRUZ',
    'LA CRUZ (BARR)',
    'LA DRAGA',
    'LA ERA',
    'LA ESMERALDA (U HAB)',
    'LA ESMERALDA I',
    'LA ESMERALDA II',
    'LA ESMERALDA III',
    'LA ESPERANZA',
    'LA ESTACION',
    'LA FAMA',
    'LA FORESTAL',
    'LA FORESTAL 1',
    'LA FORESTAL 2',
    'LA FORESTAL 3',
    'LA GUADALUPANA',
    'LA GUADALUPE',
    'LA GUADALUPITA (BARR)',
    'LA HABANA',
    'LA HERRADURA',
    'LA HUERTA',
    'LA JOYA',
    'LA JOYITA',
    'LA LAGUNA TICOMAN (BARR)',
    'LA LIBERTAD - IXTLAHUACA',
    'LA LOMA',
    'LA MAGDALENA ATLITIC (PBLO)',
    'LA MAGDALENA CULHUACAN (BARR)',
    'LA MAGDALENA PETLACALCO (PBLO)',
    'LA MAGUEYERA',
    'LA MALINCHE',
    'LA MARTINICA',
    'LA MESA',
    'LA MEXICANA',
    'LA MEXICANA (AMPL)',
    'LA MILAGROSA',
    'LA NOPALERA',
    'LA NORIA',
    'LA NORIA TEPEPAN',
    'LA NUEVA ROSITA',
    'LA OTRA BANDA',
    'LA PALMA',
    'LA PALMITA',
    'LA PASTORA',
    'LA PATERA-CONDOMODULOS (U HAB)',
    'LA PERA XOCHINAHUAC (U HAB)',
    'LA PILA',
    'LA PLANTA',
    'LA POLVORILLA',
    'LA PRADERA',
    'LA PRADERA I (U HAB)',
    'LA PRADERA II (U HAB)',
    'LA PRECIOSA',
    'LA PRESA',
    'LA PRESA SECCION HORNOS',
    'LA PRIMAVERA',
    'LA PURISIMA TICOMAN (BARR)',
    'LA RAZA',
    'LA REGADERA',
    'LA RETAMA',
    'LA SANTISIMA (BARR)',
    'LA TORTUGA XOLALPA-HCO COLEGIO MILITAR',
    'LA TURBA',
    'LA VENTA',
    'LA VIRGEN 1170 (U HAB)',
    'LADERA GRANDE O LA CEBADITA',
    'LAS AGUILAS',
    'LAS AGUILAS (AMPL)',
    'LAS AGUILAS SECC HORNOS',
    'LAS AMERICAS (U HAB)',
    'LAS ARBOLEDAS',
    'LAS CABAAS',
    'LAS CALLES (BARR)',
    'LAS CAMPANAS',
    'LAS CRUCES',
    'LAS CUEVITAS',
    'LAS HUERTAS',
    'LAS LAJAS',
    'LAS PALMAS',
    'LAS PEAS I',
    'LAS PEAS II',
    'LAS SALINAS',
    'LAS TINAJAS',
    'LAS TROJES COAPA (U HAB)',
    'LEGARIA',
    'LEGARIA (U HAB)',
    'LETRAN VALLE',
    'LEYES DE REFORMA 1A SECCION',
    'LEYES DE REFORMA 2A SECCION',
    'LEYES DE REFORMA 3A SECCION I',
    'LEYES DE REFORMA 3A SECCION II',
    'LIBERACION',
    'LIBERACION PROLETARIA',
    'LIBERALES DE 1857',
    'LIBERTAD',
    'LINDAVISTA I',
    'LINDAVISTA II',
    'LINDAVISTA VALLEJO (U HAB)',
    'LLANO REDONDO',
    'LOMA BONITA-AMPLIACION TEPEXIMILPA',
    'LOMA DE LA PALMA',
    'LOMA DEL PADRE',
    'LOMA HERMOSA (CONJ HAB)',
    'LOMAS ALTAS',
    'LOMAS ALTAS DE PADIERNA SUR',
    'LOMAS DE  PADIERNA I',
    'LOMAS DE  PADIERNA II',
    'LOMAS DE AXOMIATLA',
    'LOMAS DE BARRILACO (LOMAS DE CHAPULTEPEC)',
    'LOMAS DE BECERRA',
    'LOMAS DE BECERRA (U HAB)',
    'LOMAS DE BECERRA ARVIDE (U HAB)',
    'LOMAS DE BEZARES',
    'LOMAS DE CAPULA',
    'LOMAS DE CAPULIN',
    'LOMAS DE CENTENARIO (U HAB)',
    'LOMAS DE CHAMONTOYA',
    'LOMAS DE CHAPULTEPEC',
    'LOMAS DE CUAUTEPEC',
    'LOMAS DE CUILOTEPEC',
    'LOMAS DE GUADALUPE',
    'LOMAS DE LA ERA',
    'LOMAS DE LA ESTANCIA I',
    'LOMAS DE LA ESTANCIA II',
    'LOMAS DE LAS AGUILAS',
    'LOMAS DE LOS ANGELES TETELPAN',
    'LOMAS DE LOS CEDROS',
    'LOMAS DE MEMETLA',
    'LOMAS DE NUEVO MEXICO',
    'LOMAS DE PADIERNA (AMPL)',
    'LOMAS DE PLATEROS (U HAB) I',
    'LOMAS DE PLATEROS (U HAB) II',
    'LOMAS DE PUERTA GRANDE',
    'LOMAS DE REFORMA',
    'LOMAS DE REFORMA (LOMAS DE CHAPULTEPEC)',
    'LOMAS DE SAN ANGEL INN',
    'LOMAS DE SAN BERNABE',
    'LOMAS DE SAN BERNABE (AMPL)',
    'LOMAS DE SAN JUAN IXHUATEPEC (2A SECCION)',
    'LOMAS DE SAN LORENZO I',
    'LOMAS DE SAN LORENZO II',
    'LOMAS DE SANTA CRUZ MEYEHUALCO',
    'LOMAS DE SANTA FE',
    'LOMAS DE SANTA FE II',
    'LOMAS DE SANTO DOMINGO',
    'LOMAS DE SANTO DOMINGO (AMPL)',
    'LOMAS DE SOTELO',
    'LOMAS DE TARANGO',
    'LOMAS DE TEPEMECATL',
    'LOMAS DE TETELPAN',
    'LOMAS DE TEXCALATLACO',
    'LOMAS DE VISTA HERMOSA',
    'LOMAS DE ZARAGOZA',
    'LOMAS DEL CHAMIZAL',
    'LOMAS DEL PEDREGAL',
    'LOMAS EL MANTO',
    'LOMAS ESTRELLA  III (U HAB)',
    'LOMAS ESTRELLA 1A SECC (FRACC)',
    'LOMAS ESTRELLA 2A SECC I',
    'LOMAS ESTRELLA 2A SECC II',
    'LOMAS HIDALGO',
    'LOMAS QUEBRADAS',
    'LOMAS VIRREYES (LOMAS DE CHAPULTEPEC)',
    'LOPEZ PORTILLO',
    'LORENZO BOTURINI',
    'LORETO',
    'LORETO PEA POBRE (U HAB)',
    'LOS  ENCINOS',
    'LOS ANGELES',
    'LOS ANGELES APANOAYA',
    'LOS CEDROS',
    'LOS CEDROS (FRACC)',
    'LOS CERRILLOS I',
    'LOS CERRILLOS II',
    'LOS CERRILLOS III',
    'LOS CIPRESES',
    'LOS GAMITOS',
    'LOS GIRASOLES I',
    'LOS GIRASOLES II',
    'LOS GIRASOLES III',
    'LOS MORALES (POLANCO)',
    'LOS OLIVOS',
    'LOS OLIVOS (FRACC)',
    'LOS OLIVOS (U HAB)',
    'LOS PADRES',
    'LOS PASTORES',
    'LOS PICOS VI B',
    'LOS REYES',
    'LOS REYES (AMPL)',
    'LOS REYES (BARR)',
    'LOS REYES (PBLO)',
    'LOS ROBLES (FRACC)',
    'LOS SAUCES (FRACC)',
    'LOS VOLCANES',
    'LUIS DONALDO COLOSIO',
    'M MAZA DE JUAREZ (U HAB)',
    'MA ESTHER ZUNO DE ECHEVERRIA-TLALPUENTE',
    'MAGDALENA  MIXHUCA',
    'MAGDALENA  MIXHUCA (PBLO)',
    'MAGDALENA ATLAZOLPA (PBLO)',
    'MAGDALENA DE LAS SALINAS',
    'MALACATES',
    'MALACATES (AMPL)',
    'MALVINAS MEXICANAS',
    'MANUEL AVILA CAMACHO',
    'MANUEL RIVERA ANAYA CROC I (U HAB)',
    'MANZANASTITLA',
    'MARGARITA M DE JUAREZ',
    'MARIA DEL CARMEN',
    'MARIA G DE GARCIA RUIZ',
    'MARINA NACIONAL (U HAB)',
    'MARTIN CARRERA I',
    'MARTIN CARRERA II',
    'MARTIRES DE RIO BLANCO',
    'MARTIRES DE RIO BLANCO (AMPL)',
    'MARTIRES DE TACUBAYA',
    'MAXIMINO AVILA CAMACHO',
    'MAZA',
    'MEDIA LUNA',
    'MEMETLA',
    'MERCED BALBUENA',
    'MERCED GOMEZ',
    'MESA LOS HORNOS, TEXCALTENCO',
    'METROPOLITANO (U HAB)',
    'MEXICALTZINGO (PBLO)',
    'MEXICO NUEVO',
    'MICHOACANA',
    'MICHOACANA (AMPL)',
    'MIGUEL ALEMAN',
    'MIGUEL DE LA MADRID HURTADO',
    'MIGUEL GAONA ARMENTA',
    'MIGUEL HIDALGO',
    'MIGUEL HIDALGO (U HAB)',
    'MIGUEL HIDALGO OTE',
    'MILITAR 1 K LOMAS DE SOTELO (U HAB)',
    'MILITAR MARTE',
    'MILPA DEL CEDRO',
    'MINAS DE CRISTO',
    'MINAS POLVORILLA (U HAB)',
    'MINERVA',
    'MIRADOR 1A SECC',
    'MIRADOR 2A y 3A SECC',
    'MIRADOR DEL VALLE',
    'MIRADOR I',
    'MIRADOR II',
    'MIRASOLES (U HAB)',
    'MIRAVALLE',
    'MIXCOAC',
    'MIXCOATL',
    'MOCTEZUMA 1A SECCION',
    'MOCTEZUMA 2A SECCION I',
    'MOCTEZUMA 2A SECCION II',
    'MOCTEZUMA 2A SECCION III',
    'MOCTEZUMA 2A SECCION IV',
    'MODELO (U)',
    'MODELO PENSIL',
    'MODERNA',
    'MOLINO DE ROSAS',
    'MOLINO DE ROSAS (AMPL)',
    'MOLINO DE SANTO DOMINGO',
    'MOLINO DE SANTO DOMINGO (U HAB)',
    'MOLINO DEL REY',
    'MONTE ALBAN',
    'MONTE ALTO',
    'MONTE DE PIEDAD',
    'MORALES SECCION ALAMEDA (POLANCO)',
    'MORALES SECCION PALMAS (POLANCO)',
    'MORELOS I',
    'MORELOS II',
    'MORELOS III',
    'MOSCO CHINAMPA',
    'MOVIMIENTO ORGANIZADO DE TLALPAN-EL MIRADOR (RNCDA)',
    'MOYOCOYANI (U HAB)',
    'NA HAL TI (U HAB)',
    'NAPOLES',
    'NAPOLES (AMPL)',
    'NARCISO BASSOLS (U HAB)',
    'NARCISO MENDOZA VILLA COAPA SUPERMANZANA 8 (U HAB)',
    'NARCISO MENDOZA-VILLA COAPA SUPER MANZANA 1 (U HAB)',
    'NARCISO MENDOZA-VILLA COAPA SUPER MANZANA 2 (U HAB)',
    'NARCISO MENDOZA-VILLA COAPA SUPER MANZANA 3 (U HAB)',
    'NARCISO MENDOZA-VILLA COAPA SUPER MANZANA 6 (U HAB)',
    'NARCISO MENDOZA-VILLA COAPA SUPER MANZANA 7 (U HAB)',
    'NARVARTE I',
    'NARVARTE II',
    'NARVARTE III',
    'NARVARTE IV',
    'NARVARTE V',
    'NARVARTE VI',
    'NATIVITAS',
    'NATIVITAS (U HAB)',
    'NATIVITAS LA JOYA (AMPL)',
    'NAVIDAD (GRANJAS DE NAVIDAD)',
    'NEXTENGO (BARR)',
    'NEXTITLA',
    'NICOLAS BRAVO',
    'NIO JESUS (BARR)',
    'NIOS HEROES DE CHAPULTEPEC',
    'NOCHE BUENA',
    'NONOALCO',
    'NONOALCO-TLATELOLCO (U HAB) I',
    'NONOALCO-TLATELOLCO (U HAB) II',
    'NONOALCO-TLATELOLCO (U HAB) III',
    'NORMA ISSSTE (U HAB)',
    'NUEVA ARGENTINA (ARGENTINA PONIENTE)',
    'NUEVA ATZACOALCO I',
    'NUEVA ATZACOALCO II',
    'NUEVA ATZACOALCO III',
    'NUEVA DIAZ ORDAZ',
    'NUEVA EL ROSARIO',
    'NUEVA ESPAA',
    'NUEVA GENERACION (U HAB)',
    'NUEVA INDUSTRIAL VALLEJO (FRACC)',
    'NUEVA ORIENTAL COAPA-EX HACIENDA COAPA',
    'NUEVA SANTA MARIA',
    'NUEVA STA ANITA',
    'NUEVA TENOCHTITLAN',
    'NUEVA VALLEJO',
    'NUEVO RENACIMIENTO DE AXALCO',
    'NUEVO SAN RAFAEL (BARR)',
    'OBRERA I',
    'OBRERA II',
    'OBRERA III',
    'OBRERA IV',
    'OBRERO POPULAR',
    'OBSERVATORIO',
    'OCHO DE AGOSTO',
    'OCOTILLOS',
    'OCOTLA - OCOTLA CHICO',
    'OJO DE AGUA',
    'OLIMPICA',
    'OLIVAR DE LOS PADRES',
    'OLIVAR DEL CONDE 1RA SECCION I',
    'OLIVAR DEL CONDE 1RA SECCION II',
    'OLIVAR DEL CONDE 2DA SECCION I',
    'OLIVAR DEL CONDE 2DA SECCION II',
    'OLIVAR SANTA MARIA',
    'ORIENTE (AMPL)',
    'OXTOPULCO UNIVERSIDAD',
    'PALMAS',
    'PALMAS AXOTITLA',
    'PALMATITLA',
    'PALMITAS',
    'PALMITAS (POLANCO)',
    'PALO ALTO (GRANJAS)',
    'PANAMERICANA',
    'PANAMERICANA (AMPL)',
    'PANTACO (U HAB)',
    'PANTITLAN I',
    'PANTITLAN II',
    'PANTITLAN III',
    'PANTITLAN IV',
    'PANTITLAN V',
    'PARAISO',
    'PARAISO (AMPL)',
    'PARAISO SANTA CATARINA',
    'PARAJE 38',
    'PARAJE EL CABALLITO',
    'PARAJE SAN JUAN',
    'PARAJE ZACATEPEC',
    'PARAJES BUENAVISTA (TETECON)',
    'PARQUE METROPOLITANO',
    'PARQUE RESIDENCIAL SAN ANTONIO (U HAB)',
    'PARQUE SAN ANDRES',
    'PARQUES DEL PEDREGAL',
    'PARRES EL GUARDA (PBLO)',
    'PASEO DE LAS LOMAS-SAN GABRIEL',
    'PASEOS DE CHURUBUSCO',
    'PASEOS DE TAXQUEA I',
    'PASEOS DE TAXQUEA II',
    'PASEOS DEL SUR',
    'PASTEROS',
    'PATRIMONIO FAMILIAR',
    'PAULINO NAVARRO',
    'PEA ALTA',
    'PEDREGAL 2 (CONJ HAB)',
    'PEDREGAL DE LA ZORRA',
    'PEDREGAL DE LAS AGUILAS',
    'PEDREGAL DE SAN ANGEL (AMPL)',
    'PEDREGAL DE SAN FRANCISCO (FRACC)',
    'PEDREGAL DE SANTA URSULA I',
    'PEDREGAL DE SANTA URSULA II',
    'PEDREGAL DE SANTA URSULA III',
    'PEDREGAL DE SANTA URSULA IV',
    'PEDREGAL DE SN NICOLAS 1A SECC',
    'PEDREGAL DE SN NICOLAS 2A SECC',
    'PEDREGAL DE SN NICOLAS 3A SECC',
    'PEDREGAL DE SN NICOLAS 4A SECC I',
    'PEDREGAL DE SN NICOLAS 4A SECC II',
    'PEDREGAL DE STA URSULA XITLA',
    'PEDREGAL DE STO DOMINGO I',
    'PEDREGAL DE STO DOMINGO II',
    'PEDREGAL DE STO DOMINGO III',
    'PEDREGAL DE STO DOMINGO IV',
    'PEDREGAL DE STO DOMINGO IX',
    'PEDREGAL DE STO DOMINGO V',
    'PEDREGAL DE STO DOMINGO VI',
    'PEDREGAL DE STO DOMINGO VII',
    'PEDREGAL DE STO DOMINGO VIII',
    'PEDREGAL DEL MAUREL',
    'PEMEX LINDAVISTA (U HAB)',
    'PEMEX PRADOS DEL ROSARIO (U HAB)',
    'PENITENCIARIA (AMPL)',
    'PENSADOR MEXICANO I',
    'PENSADOR MEXICANO II',
    'PENSIL NORTE',
    'PENSIL SAN JUANICO',
    'PENSIL SUR',
    'PEON DE LOS BAOS',
    'PEON VIEJO (U HAB)',
    'PERALVILLO I',
    'PERALVILLO II',
    'PERIODISTA',
    'PERIODISTA FRANCISCO ZARCO',
    'PETROLERA',
    'PETROLERA TAXQUEA',
    'PICOS IZTACALCO 1-A',
    'PICOS IZTACALCO 1B',
    'PICOS IZTACALCO 2A',
    'PIEDAD NARVARTE',
    'PIEDRA LARGA',
    'PILOTO (ADOLFO LOPEZ MATEOS)',
    'PILOTO (ADOLFO LOPEZ MATEOS) (AMPL)',
    'PILOTO CULHUACAN (U HAB)',
    'PINO (U HAB)',
    'PLAN DE AYALA',
    'PLANETARIO LINDAVISTA',
    'PLAZA ORIENTE (RDCIAL)',
    'PLAZUELA DEL PEDREGAL',
    'PLENITUD',
    'PLENITUD (U HAB)',
    'PLUTARCO ELIAS CALLES',
    'PLUTARCO ELIAS CALLES (U HAB)',
    'PODER POPULAR',
    'POLANCO REFORMA (POLANCO)',
    'POLVORA',
    'POLVORILLA (AMPL)',
    'PONCIANO ARRIAGA',
    'POPO',
    'POPO (AMPL)',
    'POPOTLA I',
    'POPOTLA II',
    'POPULAR RASTRO',
    'POPULAR STA TERESA',
    'PORTAL DEL SOL',
    'PORTALES I',
    'PORTALES II',
    'PORTALES III',
    'PORTALES IV',
    'PORTALES ORIENTE',
    'PORVENIR',
    'POSTAL',
    'POTRERILLO',
    'POTRERILLO (AMPL)',
    'POTRERO DE SAN BERNARDINO',
    'POTRERO DEL LLANO',
    'PRADO CHURUBUSCO',
    'PRADO COAPA 2A SECCION',
    'PRADO COAPA 3A SECCION-POTRERO ACOXPA',
    'PRADOS DE COYOACAN',
    'PRADOS DE CUAUTEPEC',
    'PRADOS DEL ROSARIO',
    'PRADOS LA PROVIDENCIA',
    'PREDIO DEGOLLADO',
    'PREDIO LA ANGOSTURA',
    'PREDIO SANTA CRUZ MEYEHUALCO',
    'PRESIDENTE MADERO (U HAB)',
    'PRESIDENTES',
    'PRESIDENTES 1RA (AMPL)',
    'PRESIDENTES 2DA (AMPL)',
    'PRESIDENTES DE MEXICO',
    'PRESIDENTES EJIDALES PRIMERA SECCION',
    'PRESIDENTES EJIDALES SEGUNDA SECCION',
    'PRIMERO DE MAYO',
    'PRIVADA CENTENARIO',
    'PRIVADA GAVILAN (U HAB)',
    'PRO HOGAR I',
    'PRO HOGAR II',
    'PROF JOSE ARTURO LOPEZ',
    'PROGRESISTA',
    'PROGRESO DEL SUR',
    'PROGRESO NACIONAL (AMPL)',
    'PROGRESO NACIONAL I',
    'PROGRESO NACIONAL II',
    'PROGRESO TIZAPAN',
    'PROGRESO TLALPAN',
    'PROVIDENCIA',
    'PROVIDENCIA (AMPL)',
    'PROVIDENCIA I',
    'PROVIDENCIA II',
    'PROVIDENCIA III',
    'PUEBLA',
    'PUEBLO NUEVO',
    'PUEBLO NUEVO ALTO',
    'PUEBLO NUEVO BAJO',
    'PUEBLO QUIETO',
    'PUENTE BLANCO',
    'PUENTE COLORADO',
    'PUENTE COLORADO (AMPL)',
    'PUENTE SIERRA',
    'PUNTA DE CEHUAYO',
    'PURISIMA ATLAZOLPA',
    'PURISIMA I',
    'QUETZALCOATL 3',
    'QUIAHUATLA',
    'RAMOS MILLAN BRAMADERO I',
    'RAMOS MILLAN BRAMADERO II',
    'RANCHO EL ROSARIO',
    'RANCHO LOS COLORINES (FRACC)',
    'REACOMODO EL CUERNITO',
    'REACOMODO PINO SUAREZ',
    'REAL DEL MONTE',
    'REAL DEL MORAL (FRACC)',
    'REAL DEL SUR-VILLAS DEL SUR-RESIDENCIAL ACOXPA',
    'REFORMA IZTACCIHUATL NORTE',
    'REFORMA IZTACCIHUATL SUR',
    'REFORMA PENSIL',
    'REFORMA POLITICA I',
    'REFORMA POLITICA II',
    'REFORMA SOCIAL',
    'RENOVACION',
    'RESIDENCIAL EMPERADORES',
    'RESIDENCIAL INSURGENTES SUR (U HAB)',
    'RESIDENCIAL LA ESCALERA (FRACC)',
    'RESIDENCIAL MARIA ISABEL',
    'RESIDENCIAL ZACATENCO',
    'REVOLUCION',
    'REVOLUCION IMSS (U HAB)',
    'REYNOSA TAMAULIPAS',
    'RICARDO FLORES MAGON',
    'RICARDO FLORES MAGON (AMPL)',
    'RINCON DE LA BOLSA',
    'RINCON DEL BOSQUE',
    'RINCON LAS HADAS-VILLA ROYALE-FUENTES Y ARCONADA COAPA',
    'RINCONADA (U HAB)',
    'RINCONADA DEL SUR (U HAB)',
    'RINCONADA EL MOLINO',
    'ROCA DE CRISTAL',
    'ROMA NORTE I',
    'ROMA NORTE II',
    'ROMA NORTE III',
    'ROMA SUR I',
    'ROMA SUR II',
    'ROMERO DE TERREROS',
    'ROMERO DE TERREROS (COND)',
    'ROMERO DE TERREROS (FRACC)',
    'ROMERO RUBIO',
    'ROMULO SANCHEZ-SAN FERNANDO (BARR)-PEA POBRE',
    'ROSAS DEL TEPEYAC',
    'ROSENDO SALAZAR (CONJ HAB)',
    'ROTARIOS (U HAB)',
    'SALVADOR DIAZ MIRON',
    'SAN  MIGUEL AMANTLA (PBLO)',
    'SAN AGUSTIN OHTENCO (PBLO)',
    'SAN ALVARO',
    'SAN ANDRES (BARR)',
    'SAN ANDRES (PBLO)',
    'SAN ANDRES AHUAYUCAN (PBLO)',
    'SAN ANDRES DE LAS SALINAS (PBLO)',
    'SAN ANDRES MIXQUIC (PBLO)',
    'SAN ANDRES TETEPILCO (PBLO)',
    'SAN ANDRES TOMATLAN (PBLO)',
    'SAN ANDRES TOTOLTEPEC (PBLO)',
    'SAN ANGEL',
    'SAN ANGEL INN',
    'SAN ANTONIO',
    'SAN ANTONIO (BARR)',
    'SAN ANTONIO (FRACC)',
    'SAN ANTONIO CULHUACAN (BARR)',
    'SAN ANTONIO TECOMITL (PBLO)',
    'SAN BARTOLO AMEYALCO',
    'SAN BARTOLO AMEYALCO (PBLO)',
    'SAN BARTOLO ATEPEHUACAN (PBLO)',
    'SAN BARTOLO CAHUALTONGO (PBLO)',
    'SAN BARTOLO EL CHICO',
    'SAN BARTOLOME XICOMULCO (PBLO)',
    'SAN BERNABE (BARR)',
    'SAN BERNABE OCOTEPEC (PBLO)',
    'SAN CLEMENTE',
    'SAN CRISTOBAL (BARR)',
    'SAN DIEGO (BARR)',
    'SAN DIEGO CHURUBUSCO',
    'SAN DIEGO OCOYOACAC',
    'SAN ESTEBAN (BARR)',
    'SAN FCO XICALTONGO',
    'SAN FELIPE DE JESUS I',
    'SAN FELIPE DE JESUS II',
    'SAN FELIPE DE JESUS III',
    'SAN FELIPE DE JESUS IV',
    'SAN FRANCISCO',
    'SAN FRANCISCO (BARR)',
    'SAN FRANCISCO APOLOCALCO',
    'SAN FRANCISCO CHIQUIMOLA',
    'SAN FRANCISCO CULHUACAN (PBLO)',
    'SAN FRANCISCO TECOXPA (PBLO)',
    'SAN FRANCISCO TETECALA (PBLO)',
    'SAN FRANCISCO TLALNEPANTLA (PBLO)',
    'SAN FRANCISCO TLALTENCO (PBLO)',
    'SAN FRANCISCO XOCOTITLA',
    'SAN GABRIEL',
    'SAN GREGORIO ATLAPULCO (PBLO)',
    'SAN IGNACIO (BARR)',
    'SAN JERONIMO ACULCO - LIDICE (PBLO)',
    'SAN JERONIMO MIACATLAN (PBLO)',
    'SAN JOAQUIN',
    'SAN JOSE',
    'SAN JOSE (BARR)',
    'SAN JOSE ACULCO',
    'SAN JOSE BUENAVISTA',
    'SAN JOSE DE LA ESCALERA',
    'SAN JOSE DE LOS CEDROS I',
    'SAN JOSE DE LOS CEDROS II',
    'SAN JOSE INSURGENTES',
    'SAN JOSE LAS PERITAS',
    'SAN JOSE OBRERO',
    'SAN JOSE TICOMAN',
    'SAN JOSE ZACATEPEC',
    'SAN JUAN',
    'SAN JUAN (BARR)',
    'SAN JUAN 2A AMPLIACIN (PJE)',
    'SAN JUAN CERRO (PJE)',
    'SAN JUAN DE ARAGON (PBLO)',
    'SAN JUAN DE ARAGON 1A SECCION (U HAB) I',
    'SAN JUAN DE ARAGON 1A SECCION (U HAB) II',
    'SAN JUAN DE ARAGON 2A SECCION (U HAB) I',
    'SAN JUAN DE ARAGON 2A SECCION (U HAB) II',
    'SAN JUAN DE ARAGON 3A SECCION (U HAB) I',
    'SAN JUAN DE ARAGON 3A SECCION (U HAB) II',
    'SAN JUAN DE ARAGON 4A  Y 5A SECCION (U HAB) I',
    'SAN JUAN DE ARAGON 4A  Y 5A SECCION (U HAB) II',
    'SAN JUAN DE ARAGON 6A SECCION (U HAB) I',
    'SAN JUAN DE ARAGON 6A SECCION (U HAB) II',
    'SAN JUAN DE ARAGON 7 SECC (U HAB) I',
    'SAN JUAN DE ARAGON 7 SECC (U HAB) II',
    'SAN JUAN III (U HAB)',
    'SAN JUAN IXTAYOPAN (PBLO)',
    'SAN JUAN JOYA (PJE)',
    'SAN JUAN TEPENAHUAC (PBLO)',
    'SAN JUAN TEPEPAN',
    'SAN JUAN TLIHUACA (PBLO)',
    'SAN JUAN XALPA I',
    'SAN JUAN XALPA II',
    'SAN JUAN Y GUADALUPE TICOMAN (BARR)',
    'SAN JUANICO NEXTIPAC (PBLO)',
    'SAN LORENZO',
    'SAN LORENZO (BARR)',
    'SAN LORENZO 870 (U HAB)',
    'SAN LORENZO ACOPILCO (PBLO)',
    'SAN LORENZO ATEMOAYA (PBLO)',
    'SAN LORENZO HUIPULCO',
    'SAN LORENZO LA CEBADA I',
    'SAN LORENZO LA CEBADA II',
    'SAN LORENZO TEZONCO (BARR)',
    'SAN LORENZO TEZONCO (PBLO)',
    'SAN LORENZO TEZONCO I (U HAB)',
    'SAN LORENZO TEZONCO II (U HAB)',
    'SAN LORENZO TLACOYUCAN (PBLO)',
    'SAN LORENZO TLALTENANGO',
    'SAN LORENZO XICOTENCATL (PBLO)',
    'SAN LUCAS (BARR)',
    'SAN LUCAS XOCHIMANCA (PBLO)',
    'SAN LUIS TLAXIALTEMALCO (PBLO)',
    'SAN MARCOS (AMPL)',
    'SAN MARCOS (BARR)',
    'SAN MARTIN XOCHINAHUAC (PBLO)',
    'SAN MATEO',
    'SAN MATEO (BARR)',
    'SAN MATEO TLALTENANGO (PBLO)',
    'SAN MATEO XALPA (PBLO)',
    'SAN MIGUEL',
    'SAN MIGUEL  XICALCO (PBLO)',
    'SAN MIGUEL (AMPL)',
    'SAN MIGUEL (BARR)',
    'SAN MIGUEL AJUSCO (PBLO)',
    'SAN MIGUEL CHAPULTEPEC I',
    'SAN MIGUEL CHAPULTEPEC II',
    'SAN MIGUEL CUAUTEPEC',
    'SAN MIGUEL TEHUISCO-LOS ANGELES-AYOMETITLA',
    'SAN MIGUEL TEOTONGO I',
    'SAN MIGUEL TEOTONGO II',
    'SAN MIGUEL TEOTONGO III',
    'SAN MIGUEL TEOTONGO IV',
    'SAN MIGUEL TOPILEJO (PBLO)',
    'SAN MIGUEL TOXIAC',
    'SAN MIGUEL ZAPOTITLA',
    'SAN MIGUEL-LA ESCALERA (BARR)',
    'SAN NICOLAS II',
    'SAN NICOLAS TETELCO (PBLO)',
    'SAN NICOLAS TOLENTINO I',
    'SAN NICOLAS TOLENTINO II',
    'SAN NICOLAS TOTOLAPAN',
    'SAN NICOLAS TOTOLAPAN (PBLO)',
    'SAN PABLO (BARR)',
    'SAN PABLO 396-CONJ HAB SAN PABLO (U HAB)',
    'SAN PABLO CHIMALPA (PBLO)',
    'SAN PABLO I, II Y V-LOMAS DEL PARAISO',
    'SAN PABLO OZTOTEPEC (PBLO)',
    'SAN PABLO TEPETLAPA (PBLO)',
    'SAN PABLO XALPA (U HAB)',
    'SAN PEDRO (BARR)',
    'SAN PEDRO APOSTOL (BARR)',
    'SAN PEDRO ATOCPAN (PBLO)',
    'SAN PEDRO CUAJIMALPA (PBLO)',
    'SAN PEDRO DE LOS  PINOS',
    'SAN PEDRO DE LOS PINOS',
    'SAN PEDRO EL CHICO',
    'SAN PEDRO IZTACALCO',
    'SAN PEDRO MARTIR (PBLO)',
    'SAN PEDRO TLAHUAC (PBLO)',
    'SAN PEDRO XALPA (AMPL) I',
    'SAN PEDRO XALPA (AMPL) II',
    'SAN PEDRO XALPA (PBLO)',
    'SAN PEDRO ZACATENCO (PBLO)',
    'SAN RAFAEL',
    'SAN RAFAEL I',
    'SAN RAFAEL II',
    'SAN RAFAEL TICOMAN (BARR)',
    'SAN SALVADOR CUAUHTENCO (PBLO)',
    'SAN SALVADOR XOCHIMANCA',
    'SAN SEBASTIAN',
    'SAN SEBASTIAN TECOLOXTITLAN (PBLO)',
    'SAN SIMON CULHUACAN (BARR)',
    'SAN SIMON TICUMAC',
    'SAN SIMON TOLNAHUAC',
    'SANTA ANA TLACOTENCO (PBLO)',
    'SANTA ANITA',
    'SANTA APOLONIA (BARR)',
    'SANTA BARBARA (BARR) I',
    'SANTA BARBARA (BARR) II',
    'SANTA BARBARA (PBLO)',
    'SANTA CATARINA (AMPL)',
    'SANTA CATARINA (BARR)',
    'SANTA CATARINA (PBLO)',
    'SANTA CATARINA YECAHUIZOTL (PBLO)',
    'SANTA CECILIA',
    'SANTA CECILIA TEPETLAPA (PBLO)',
    'SANTA CRUCITA (BARR)',
    'SANTA CRUZ',
    'SANTA CRUZ ACALPIXCA (PBLO)',
    'SANTA CRUZ ACAYUCAN (PBLO)',
    'SANTA CRUZ AVIACION',
    'SANTA CRUZ CHAVARRIETA',
    'SANTA CRUZ DE LAS SALINAS',
    'SANTA CRUZ MEYEHUALCO (PBLO)',
    'SANTA CRUZ MEYEHUALCO (U HAB) I',
    'SANTA CRUZ MEYEHUALCO (U HAB) II',
    'SANTA CRUZ VI (U HAB)',
    'SANTA CRUZ VII (U HAB)',
    'SANTA CRUZ XOCHITEPEC (PBLO)',
    'SANTA FE',
    'SANTA FE (U HAB)',
    'SANTA FE KM 8.5',
    'SANTA INES',
    'SANTA ISABEL TOLA (PBLO)',
    'SANTA LUCIA',
    'SANTA LUCIA (BARR)',
    'SANTA LUCIA (PBLO)',
    'SANTA LUCIA REACOMODO',
    'SANTA MARIA (U HAB)',
    'SANTA MARIA AZTAHUACAN (EJ) I',
    'SANTA MARIA AZTAHUACAN (EJ) II',
    'SANTA MARIA AZTAHUACAN (U HAB)',
    'SANTA MARIA DEL MONTE',
    'SANTA MARIA INSURGENTES',
    'SANTA MARIA LA RIBERA I',
    'SANTA MARIA LA RIBERA II',
    'SANTA MARIA LA RIBERA IV',
    'SANTA MARIA MALINALCO (PBLO)',
    'SANTA MARIA NATIVITAS (PBLO)',
    'SANTA MARIA NONOALCO',
    'SANTA MARIA TEPEPAN (PBLO)',
    'SANTA MARIA TOMATLAN',
    'SANTA MARIA TOMATLAN (AMPL)',
    'SANTA MARIA TOMATLAN (PBLO)',
    'SANTA MARTHA ACATITLA',
    'SANTA MARTHA ACATITLA SUR (AMPL)',
    'SANTA MARTHA ACATITLA_(PBLO)',
    'SANTA MARTHA DEL SUR',
    'SANTA ROSA',
    'SANTA ROSA XOCHIAC (PBLO)',
    'SANTA TERESA',
    'SANTA URSULA COAPA (PBLO)',
    'SANTA URSULA COYOACAN',
    'SANTA URSULA XITLA',
    'SANTIAGO ACAHUALTEPEC (PBLO)',
    'SANTIAGO AHUIZOTLA (PBLO)',
    'SANTIAGO ATEPETLAC',
    'SANTIAGO ATEPETLAC (LA SELVITA) (U HAB)',
    'SANTIAGO ATZACOALCO (PBLO)',
    'SANTIAGO NORTE',
    'SANTIAGO SUR',
    'SANTIAGO TEPALCATLALPAN (PBLO)',
    'SANTIAGO TULYEHUALCO (PBLO)',
    'SANTIAGO ZAPOTITLAN (PBLO)',
    'SANTISIMA TRINIDAD',
    'SANTO DOMINGO (PBLO)',
    'SANTO TOMAS',
    'SANTO TOMAS AJUSCO (PBLO)',
    'SAUZALES CEBADALES (U HAB)',
    'SCT (U HAB)',
    'SEARS ROEBUCK (U HAB)',
    'SECCION XVI',
    'SECTOR NAVAL',
    'SECTOR POPULAR',
    'SELENE (AMPL)',
    'SELENE 1a SECC',
    'SELENE 2da SECC',
    'SEVILLA',
    'SIDERAL',
    'SIERRA DEL VALLE',
    'SIETE MARAVILLAS',
    'SIMON  BOLIVAR',
    'SINATEL',
    'SINATEL (AMPL)',
    'SINDICATO MEXICANO DE ELECTRICISTAS',
    'SN JUAN  TEPEXIMILPA (AMPL)',
    'SN JUAN TEPEXIMILPA',
    'SOLIDARIDAD',
    'SOLIDARIDAD EL SALADO (U HAB)',
    'SOLIDARIDAD NACIONAL',
    'STA CRUZ ATOYAC',
    'STA ISABEL INDUSTRIAL',
    'STA MA AZTAHUACAN (PBLO)',
    'STA MARTHA ACATITLA NTE (AMPL) I',
    'STA MARTHA ACATITLA NTE (AMPL) II',
    'STUNAM CULHUACAN (U HAB)',
    'SUBESTACION',
    'SUTIC VALLEJO (U HAB)',
    'TABACALERA',
    'TABLAS DE SAN AGUSTIN',
    'TACUBA',
    'TACUBAYA',
    'TARANGO (U HAB)',
    'TATA LZARO',
    'TAXQUEA',
    'TECACALANCO',
    'TECOLALCO',
    'TECORRAL',
    'TEJOCOTE',
    'TEMPILULI',
    'TENORIOS',
    'TENORIOS (AMPL)',
    'TENORIOS INFONAVIT 1 Y 2 (U HAB)',
    'TEOZOMA',
    'TEPALCATES I',
    'TEPALCATES II',
    'TEPANTITLAMILCO',
    'TEPEACA',
    'TEPEACA (AMPL)',
    'TEPEPAN (AMPL)',
    'TEPETATAL',
    'TEPETLICA EL ALTO-3 DE MAYO',
    'TEPETONGO',
    'TEPEYAC INSURGENTES',
    'TEPOPOTLA',
    'TESMIC (RDCIAL)',
    'TETELPAN',
    'TETELPAN (PBLO)',
    'TETENCO (PJE)',
    'TETLALPAN',
    'TEXCALCO',
    'TEXCOCO EL SALADO',
    'TEZONTITLA',
    'TEZONTITLA - EL CALVARIO (AMPL)',
    'TEZONTITLA-ZOMPANTITLA',
    'TEZOZOMOC',
    'TIERRA BLANCA',
    'TIERRA COLORADA',
    'TIERRA NUEVA',
    'TIERRA UNIDA',
    'TIERRA Y LIBERTAD',
    'TIZAMPAMPANO',
    'TIZAPAN_TIZAPAN SAN ANGEL',
    'TLACAELEL',
    'TLACAMACA',
    'TLACOPAC',
    'TLACOQUEMECATL DEL VALLE',
    'TLACOTAL RAMOS MILLAN',
    'TLACOYAQUE',
    'TLACOYAQUE (AMPL)',
    'TLACOYAQUE (BARR)',
    'TLACUITLAPA',
    'TLACUITLAPA 2DO REACOMODO (AMPL)',
    'TLALCOLIGIA',
    'TLALMILLE',
    'TLALPAN CENTRO',
    'TLALPEXCO',
    'TLALTENCO (U HAB)',
    'TLANEZICALLI (U HAB)',
    'TLAPECHICO',
    'TLATILCO',
    'TLATILCO (U HAB)',
    'TLAXOPAN',
    'TLAXPANA',
    'TLAZINTLA',
    'TOLTECA',
    'TORIELLO GUERRA',
    'TORRE BLANCA',
    'TORRE BLANCA (AMPL)',
    'TORRES DE MIXCOAC (U HAB)',
    'TORRES DE PADIERNA',
    'TORRES DE POTRERO',
    'TORRES DE QUIROGA (U HAB)',
    'TORRES DE SAN JUAN (U HAB)',
    'TORRES DE SAN JUAN 1B (U HAB)',
    'TORRES LINDAVISTA (FRACC)',
    'TORRES SAN ANTONIO (U HAB)',
    'TORRES SAN PEDRO (U HAB)',
    'TORRES TOREO',
    'TRABAJADORES DEL HIERRO',
    'TRANSITO',
    'TRES ESTRELLAS',
    'TRES FUENTES (U HAB)',
    'TRES MOSQUETEROS',
    'TRIUNFO DE LA REPUBLICA',
    'TULA (BARR)',
    'UN HOGAR PARA CADA TRABAJADOR',
    'UN HOGAR PARA NOSOTROS',
    'UNIDAD POPULAR TEPEACA',
    'UNIDADES HABITACIONALES DE SANTA ANA PONIENTE I',
    'UNIDADES HABITACIONALES DE SANTA ANA PONIENTE II',
    'UNIDADES HABITACIONALES DE TENORIOS',
    'UNION OLIVOS',
    'UNION POPULAR EMILIANO ZAPATA (U HAB)',
    'USCOVI (U HAB)',
    'VALENTIN GOMEZ FARIAS',
    'VALLE DE LUCES (U HAB)',
    'VALLE DE LUCES I',
    'VALLE DE LUCES II',
    'VALLE DE MADERO',
    'VALLE DE SAN LORENZO I',
    'VALLE DE SAN LORENZO II',
    'VALLE DE TEPEPAN',
    'VALLE DEL SUR',
    'VALLE DEL TEPEYAC',
    'VALLE ESCONDIDO',
    'VALLE GOMEZ',
    'VALLE VERDE',
    'VALLEJO I',
    'VALLEJO II',
    'VALLEJO PONIENTE',
    'VASCO DE QUIROGA',
    'VENTURA PEREZ DE ALBA',
    'VENUSTIANO CARRANZA',
    'VENUSTIANO CARRANZA (AMPL)',
    'VERACRUZANA (AMPL)',
    'VERANO',
    'VERGEL DE COYOACAN-VERGEL DEL SUR',
    'VERONICA ANZURES',
    'VERONICA CASTRO',
    'VERTIZ NARVARTE',
    'VIADUCTO  - BALBUENA )CONJ HAB)',
    'VIADUCTO PIEDAD',
    'VICENTE GUERRERO SUPER MANZANA 1 (U HAB)',
    'VICENTE GUERRERO SUPER MANZANA 2 (U HAB)',
    'VICENTE GUERRERO SUPER MANZANA 3 (U HAB)',
    'VICENTE GUERRERO SUPER MANZANA 4 (U HAB)',
    'VICENTE GUERRERO SUPER MANZANA 5 (U HAB)',
    'VICENTE GUERRERO SUPER MANZANA 6 (U HAB)',
    'VICENTE GUERRERO SUPER MANZANA 7 (U HAB)',
    'VICTORIA DE LAS DEMOCRACIAS',
    'VIEJO EJIDO SANTA URSULA COAPA',
    'VILLA CENTROAMERICANA (U HAB)',
    'VILLA COAPA (RDCIAL)',
    'VILLA COYOACAN',
    'VILLA DE ARAGON',
    'VILLA DE ARAGON (FRACC)',
    'VILLA DE CORTES',
    'VILLA DEL PUENTE FOVISSSTE  (U HAB)',
    'VILLA GUSTAVO A MADERO',
    'VILLA HERMOSA',
    'VILLA LAZARO CARDENAS',
    'VILLA MILPA ALTA (PBLO)',
    'VILLA OLIMPICA LIBERADOR MIGUEL HIDALGO (U HAB)',
    'VILLA PANAMERICANA 1ERA. SECCIN (U HAB)',
    'VILLA PANAMERICANA 2DA. SECCIN (U HAB)',
    'VILLA PANAMERICANA 3ERA. SECCIN (U HAB)',
    'VILLA PANAMERICANA 4TA. SECCIN (U HAB)',
    'VILLA PANAMERICANA 5TA. SECCIN (U HAB)',
    'VILLA PANAMERICANA 6TA. SECCIN (U HAB)',
    'VILLA PANAMERICANA 7MA. SECCIN (U HAB)',
    'VILLA PROGRESISTA',
    'VILLA QUIETUD (FRACC)',
    'VILLA SOLIDARIDAD',
    'VILLA VERDUN',
    'VILLA XOCHIMILCO (U HAB)',
    'VILLAS AZCAPOTZALCO (U HAB)',
    'VILLAS DE LOS TRABAJADORES (GDF) (U HAB)',
    'VILLAS DEL PEDREGAL (U HAB)',
    'VISTA ALEGRE',
    'VISTA HERMOSA',
    'VISTAS DEL MAUREL (U HAB)',
    'VISTAS DEL PEDREGAL',
    'VIVEROS DE COACTETLAN',
    'XALPA',
    'XALPA I',
    'XALPA II',
    'XALPA III',
    'XALTOCAN (BARR)',
    'XAXALCO',
    'XAXALIPAC',
    'XICALHUACAN',
    'XOCHINAHUAC (U HAB)',
    'XOCO',
    'XOPA (U HAB)',
    'XOTEPINGO',
    'ZACAHUITZCO',
    'ZACATIENDA',
    'ZAPOTE-LUIS DONALDO COLOSIO (U HABS)',
    'ZAPOTLA',
    'ZENON DELGADO',
    'ZENTLAPATL',
    'ZONA ESCOLAR I',
    'ZONA ESCOLAR II',
    'ZONA ESCOLAR ORIENTE',
    'ZONA MILITAR FAVE SEDENA (U HAB)',
    'ZOTOLTITLA'
  ]
}

export default availableCities
