import {Option} from '@hooks/useTimeField/types'

export interface BackendOption<Value> {
  label: Nullable<string>
  value: Nullable<Value>
}

export type Nullable<T> = T | null
export function isNotNull<T>(value: T): value is Exclude<T, null> {
  return value !== null
}

export function isNotUndefined<T>(value: T): value is Exclude<T, undefined> {
  return value !== undefined
}

export function isNotUndefinedNorNull<T>(value: T): value is Exclude<T, undefined | null> {
  return value !== undefined && value !== null
}

export function backendOptionToOption(option?: null): null | undefined

export function backendOptionToOption<ArgumentOption extends BackendOption<string>>(
  backendOption: ArgumentOption
): Omit<ArgumentOption, 'label' | 'value'> & Option<string>

export function backendOptionToOption<ArgumentOption extends BackendOption<string>>(
  option?: ArgumentOption | null
): (Omit<ArgumentOption, 'label' | 'value'> & Option<string>) | null | undefined

export function backendOptionToOption<ArgumentOption extends BackendOption<string>>(
  backendOption?: ArgumentOption | null
): (Omit<ArgumentOption, 'label' | 'value'> & Option<string>) | null | undefined {
  if (!backendOption) return backendOption

  return {
    ...backendOption,
    // This shouldn't happen
    label: backendOption?.label ?? '',
    // This shouldn't happen
    value: backendOption?.value ?? ''
  }
}
