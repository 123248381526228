import {WEBSITE_NO_STORE_MESSAGE_CACHED_QUERY} from '@data/queries/website/websiteNoStoreMessage'
import useWebsiteId from '@hooks/useWebsiteId'
import {useMutate} from 'apollo-hooks'
import {useApolloQuery as useQuery} from 'apollo-hooks'

// if you only want to know if a certain address is out of bounds without
// using the visualization logic of this component, use the
// useAddressOutOfBounds hook.
// it has the same behaviour, but is created with the 'use' prefix to indicate
// that it should only be used as a hook, meanwhile this function is also used
// as a component, so it's preserved for compatibility reasons.
export default function AddressOutOfBounds(props) {
  const mutate = useMutate()
  const websiteId = useWebsiteId()

  const {website} = useQuery({
    query: WEBSITE_NO_STORE_MESSAGE_CACHED_QUERY,
    variables: {websiteId},
    fetchPolicy: 'cache-first',
    partial: true
  })

  if (!website || !props.placeId) return null

  return (
    website.menuConfiguration.deliveryNoStoreMessage || 'Lo sentimos, no llegamos a tu dirección'
  )
}
