import canDeliverNowFn from '@helpers/websites/canDeliverNow'
import canScheduleFn from '@helpers/websites/canSchedule'

import {DELIVER_NOW_TIME, DeliveryTimeTypeOptions} from '../../constants'
import {Option} from '../../types'

export const getDeliveryTimeType = (preferencesTime?: string) => {
  if (!preferencesTime) {
    return null
  }
  if (preferencesTime === DELIVER_NOW_TIME) {
    return DeliveryTimeTypeOptions.now
  }
  // the user has schedule order as his only option, so set it as the initial value.
  return DeliveryTimeTypeOptions.schedule
}

export const getDeliveryTimeTypeOptions = (store: {
  minimumAnticipationDays?: number
  availableScheduleDaysOptions: Option<string>[]
  schedule: {itsOpenNow?: boolean}
}) => {
  const deliveryTimeOptions: Option<number>[] = []

  if (canDeliverNowFn(store)) {
    deliveryTimeOptions.push(DeliveryTimeTypeOptions.now)
  }
  if (canScheduleFn(store)) {
    deliveryTimeOptions.push(DeliveryTimeTypeOptions.schedule)
  }
  return deliveryTimeOptions
}
